import { SearchResultUser } from '@/api/User';

export function filterTrainedUsers(
  users: SearchResultUser[] | undefined,
  techInterview: boolean,
  codingChallenge: boolean
) {
  if (!users) return;

  return users.filter((user) => {
    if (codingChallenge && techInterview) {
      return user.trained_coding_challenges && user.trained_technical_interviews;
    } else if (codingChallenge) {
      return user.trained_coding_challenges;
    } else if (techInterview) {
      return user.trained_technical_interviews;
    }
    return 1;
  });
}
