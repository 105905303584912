import { Table, TableData } from '@team-lightfeather/ui-react-assets';
import { Column } from 'react-table';

import { SearchUsersProficienciesResData } from '@/api/Proficiency';
import { PlainButton } from '@/components/Button';

import { mapSearchResult, ProficiencyUser } from './utils/proficiencySearch';

/**
 * Buids data table columns. Ensures each unique skill is only displayed one time in table
 * Sets column header name and points to skill value to be displayed in table
 * @param data Array of User objects
 * @returns
 */

export function buildColumns(
  data: ProficiencyUser[],
  userClickHandler?: (userId: string) => void
): Column<TableData>[] {
  const uniqueColumns = new Map();
  data.forEach((user: ProficiencyUser) => {
    Object.entries(user).forEach((entry) => {
      const [key, val] = entry;
      uniqueColumns.set(key, val);
    });
  });

  const dynamicSkillColumns: Column<TableData>[] = [];

  uniqueColumns.forEach((val: string, key: string) => {
    key !== 'id' &&
      dynamicSkillColumns.push({
        Header: key,
        accessor: key,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: (props: any) => {
          if (key === 'Name') {
            return (
              <PlainButton
                title={props.value}
                clickHandler={() => userClickHandler && userClickHandler(props.row.original.id)}
              />
            );
          }
          return props.value ?? '';
        },
      });
  });

  return dynamicSkillColumns;
}

interface UserProficiencyProps {
  searchResults: SearchUsersProficienciesResData[];
  setSelectedUser: (userId: string) => void;
}

export const UsersProficiencyList = ({ searchResults, setSelectedUser }: UserProficiencyProps) => {
  const data = searchResults.map((result) => mapSearchResult(result));
  const columns = buildColumns(data, setSelectedUser);

  return (
    <>
      <Table columns={columns} data={data as unknown as TableData[]} sortable />
    </>
  );
};
