import { Button } from '@team-lightfeather/ui-react-assets';
import { toast } from 'react-toastify';

import api from '@/api';
import { GetUsersDTO } from '@/api/User';
import { CreateQueryParams, createQueryParams } from '@/utils/queryParams';

interface ProfPayload {
  skill?: string;
  level?: number;
  required?: boolean;
}
interface SearchUsersPayload {
  contract?: string[];
  experience?: number | undefined;
  education?: string[];
  certifications?: string[];
  proficiencies?: ProfPayload[];
}

interface ShareSearchPayload {
  payload: SearchUsersPayload;
  className?: string;
  fullPath?: boolean;
}

export const ShareSearchBtn = ({
  payload,
  className = '',
  fullPath = true,
}: ShareSearchPayload) => {
  const clickHandler = async () => {
    payload.contract = payload.contract ? payload.contract : [];
    payload.education = payload.education ? payload.education : [];
    payload.certifications = payload.certifications ? payload.certifications : [];
    payload.proficiencies = payload.proficiencies ? payload.proficiencies : [];

    try {
      const result: GetUsersDTO = await api.post('/users/search', payload);

      if (!result.success) throw new Error();

      const params = createQueryParams(payload as CreateQueryParams);

      fullPath
        ? navigator.clipboard.writeText(location.origin + location.pathname + params)
        : navigator.clipboard.writeText(params);

      toast.success('Search has been copied to clipboard');
    } catch (error) {
      toast.error('Could not save search!');
    }
  };

  return (
    <Button className={className} onClick={clickHandler}>
      SHARE SEARCH
    </Button>
  );
};
