import { Checkbox, Tooltip } from '@team-lightfeather/ui-react-assets';
import { useState } from 'react';

import { SearchUsersProficienciesReqDTO } from '@/api/Proficiency';
import { ProfPayload, SearchUserEducation, useSearchUsers } from '@/api/User';
import { Form } from '@/components';

import {
  CertificationsDropdownMulti,
  ContractsDropdownMulti,
  EducationDropdown,
  YearsOfExperience,
} from '../form';
import { SearchUserProficiencies } from '../searchUserProficiencies/SearchUserProficiencies';
import { defaultValues, MatrixReset } from './';
import { filterTrainedUsers } from './utils/filterTrainedUsers';

export type SearchMatrixResData = {
  contract: string[];
  education: SearchUserEducation[] | [];
  experience: number | null;
  certifications: string[];
  proficiencies: SearchUsersProficienciesReqDTO[];
};

export const MatrixSearch = () => {
  const [filterTrainedTechInterview, setFilterTrainedTechInterview] = useState(false);
  const [filterTrainedCodingChallenge, setFilterTrainedCodingChallenge] = useState(false);

  const searchUsers = useSearchUsers();

  let proficiencies: ProfPayload[] = [];

  const proficienciesReset = () => {
    proficiencies = [];
    searchUsers.reset();
  };

  const handleSearchUsersSubmit = (data: SearchMatrixResData) => {
    searchUsers.mutate({
      contract: data.contract,
      experience: data.experience ? Number(data.experience) : null,
      certifications: data.certifications,
      education: data.education ? data.education : [],
      proficiencies: proficiencies,
    });
  };

  // Results from search query
  const users = filterTrainedUsers(
    searchUsers.data?.body,
    filterTrainedTechInterview,
    filterTrainedCodingChallenge
  );

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={handleSearchUsersSubmit}
      submitOnChange={true}
      hideSubmitBtn
      classNames='maxw-full'
    >
      <h2>Matrix Search</h2>
      <div className='text-right'>
        <MatrixReset onReset={proficienciesReset} />
      </div>

      <div className='grid-row grid-gap-lg'>
        <div className='grid-col-3'>
          <ContractsDropdownMulti handleSubmit={handleSearchUsersSubmit} />
        </div>
        <div className='grid-col-3'>
          <EducationDropdown name='education' />
        </div>
        <div className='grid-col-3'>
          <YearsOfExperience />
        </div>
        <div className='grid-col-3'>
          <CertificationsDropdownMulti handleSubmit={handleSearchUsersSubmit} />
        </div>
      </div>

      <div className='grid-row flex-align-center margin-top-1'>
        <Tooltip label='Display users trained to review coding challenges submitted in the interview process'>
          <Checkbox
            className='margin-right-3'
            label='Coding Challenge Reviews'
            id='coding_challenge'
            name='coding_challenge'
            checked={filterTrainedCodingChallenge}
            onChange={() => setFilterTrainedCodingChallenge(!filterTrainedCodingChallenge)}
          />
        </Tooltip>
        <Tooltip label='Show users trained to conduct technical interviews'>
          <Checkbox
            label='Technical Interviews'
            id='technical_interview'
            name='technical_interview'
            checked={filterTrainedTechInterview}
            onChange={() => setFilterTrainedTechInterview(!filterTrainedTechInterview)}
          />
        </Tooltip>
      </div>

      <SearchUserProficiencies
        hideHeader
        searchUsersRes={users}
        callBack={(data: ProfPayload[]) => {
          if (JSON.stringify(proficiencies) !== JSON.stringify(data)) proficiencies = data;
        }}
      />
    </Form>
  );
};
