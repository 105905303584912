import { SearchUsersProficienciesReqDTO } from '@/api/Proficiency';

// Parses url query params and returns params for proficiencies
// Schema ex: any/path?proficiences=java,3,T;node,4&...
// skill,level,niceToHave;skill,level,niceToHave
// ex: URL encoded: /search-proficiencies?proficiencies=java%2C2%3Bnode.js%2C3%2Ct%3Bpython%2C5%2C
export const paramProficiencies = (params: URLSearchParams) => {
  let proficienciesParams = params.get('proficiencies');

  if (!proficienciesParams) return [];

  proficienciesParams = stripTrailingSymbol(proficienciesParams);

  let proficiencies: SearchUsersProficienciesReqDTO[] = [];

  // transforms proficience params to an array of objects
  if (proficienciesParams) {
    proficiencies = proficienciesParams
      .split(';')
      .map((p: string) => p.split(','))
      .map((x: string[]) => ({
        skill: x[0],
        level: parseInt(x[1]),
        required: x[2]?.toLowerCase() === 'true',
      }));
  }

  return proficiencies;
};

/**
 * Converts payload sent to main search into a query parameter string
 */
export interface CreateQueryParams {
  contract?: string[];
  experience?: number;
  education?: string[];
  proficiencies?: SearchUsersProficienciesReqDTO[];
}
export const createQueryParams = ({
  contract,
  experience,
  education,
  proficiencies,
}: CreateQueryParams): string => {
  const params: { [key: string]: string | number } = {};

  if (contract?.length) params.contract = contract.join(',');
  if (experience) params.experience = experience;
  if (education?.length) params.education = education.join(',');
  if (proficiencies?.length)
    params.proficiencies = proficiencies
      .map((prof) => `${prof.skill},${prof.level},${prof.required.toString().toLowerCase()}`)
      .join(';');

  const builtParams = new URLSearchParams({ ...(params as unknown as URLSearchParams) });

  return `?${builtParams.toString()}`;
  // Make sample api call to ensure no errors
};

export function stripTrailingSymbol(str: string) {
  const lastChar = str[str.length - 1];
  return lastChar === ';' || lastChar === '&' ? str.slice(0, -1) : str;
}
