import { Card, Image } from '@team-lightfeather/ui-react-assets';

import { Contract } from '@/api/Contract';
import { DateRangeDisplay } from '@/components/DateRangeDisplay';
import { ProgressBar } from '@/components/ProgressBar';
import { TagList } from '@/components/TagList';

import us_flag_shield from '../../../assets/img/us_flag_shield.png';

interface ContractDetailCardProps {
  contract: Contract;
}

export const ContractDetailCard = ({ contract }: ContractDetailCardProps) => {
  const tags = ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4'];

  const renderContractField = ({ value, subHeader }: { value: string; subHeader: string }) => {
    return (
      <div className='grid-row'>
        <h3 className='grid-col-12 margin-bottom-0'>{value}</h3>
        <span className='grid-col-12'>{subHeader}</span>
      </div>
    );
  };
  return (
    <>
      <Card.Group>
        <Card className='padding-bottom-2 width-full'>
          <Card.Header headerClassName='contract-card-header'>
            <div className='grid-row grid-gap-lg flex-align-start'>
              <div className='left-align margin-left-4'>
                <div className='grid-row'>
                  <h5 className='usa-header grid-col margin-top-0 margin-bottom-1'>
                    {contract.name}
                  </h5>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className='margin-top-3'>
            <div data-testid='contract-card-body' className='grid-row'>
              <div className='grid-col-3'>
                <Image alt='contract photo' src={us_flag_shield} width='200' />
                <TagList tagNames={tags} classNames='contract-tag-list' />
              </div>
              <div className='grid-col-6 text-left'>
                <span>{contract.status}</span>
                <h2 className='margin-0'>{contract.name}</h2>
                <span>{contract.description}</span>
                <div className='grid-row'>
                  <div className='grid-col-6'>
                    {renderContractField({
                      value: contract.point_of_contact,
                      subHeader: 'Point of Contact',
                    })}
                  </div>
                  <div className='grid-col-6'>
                    {renderContractField({
                      value: 'Agency Name Here',
                      subHeader: 'Agency',
                    })}
                  </div>
                  <div className='grid-col-6'>
                    {renderContractField({
                      value: contract.prime_contractor,
                      subHeader: 'Prime Contractor',
                    })}
                  </div>
                  <div className='grid-col-12'>
                    {renderContractField({
                      value: 'Sub Contractor 1, Sub Contractor 2',
                      subHeader: 'Sub Contractors',
                    })}
                  </div>
                </div>
              </div>
              <div className='grid-col-3 padding-left-2'>
                <ProgressBar
                  classNames='margin-bottom-1'
                  isCircular
                  minValue={0}
                  maxValue={44}
                  value={39}
                  valueText='39/44'
                  titleNode='Team Members'
                />
              </div>
            </div>
            <div className='margin-top-2'>
              <DateRangeDisplay startDate={contract.start_date} endDate={contract.end_date} />
            </div>
          </Card.Body>
        </Card>
      </Card.Group>
    </>
  );
};
