import { Modal } from '@team-lightfeather/ui-react-assets';
import { Dispatch, SetStateAction } from 'react';

import { Level } from '@/api/Level';

import { UpdateLevelForm } from './UpdateLevelForm';

interface UpdateLevelModalProps {
  level: Level;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const UpdateLevelModal = ({ level, setIsModalOpen, isModalOpen }: UpdateLevelModalProps) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      variant='large'
      aria-labelledby='UpdateLevelModal'
      open={isModalOpen}
      close={() => closeModal()}
    >
      <div className='updateLevelModal' data-testid='updateLevelModal'>
        <UpdateLevelForm level={level} onSuccess={closeModal} onCancel={closeModal} />
      </div>
    </Modal>
  );
};
