import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMessage as ValidationErrorMessage,
  FormGroup,
  Label,
} from '@team-lightfeather/ui-react-assets';
import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import Select from 'react-select';

type MultiSelectOptions = {
  value: string;
  label: string;
};

type DropDownMultiSelectFieldProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  rules?: RegisterOptions;
  label?: string;
  options: MultiSelectOptions[];
  submitOnChange?: (data: FieldValues) => void;
} & FieldValues;

export const DropDownMultiSelectField = <T extends FieldValues>({
  name,
  label,
  rules,
  options,
  submitOnChange,
  ...rest
}: DropDownMultiSelectFieldProps<T>) => {
  const methods = useFormContext<T>();
  const {
    formState: { errors },
    register,
  } = methods;

  return (
    <FormGroup>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Controller
        name={name}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              {...rest}
              className='lf-select lf-select__multi'
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              {...register(name, rules)}
              value={
                value
                  ? value.map((c: string) => {
                      return options.find((o) => o.value === c);
                    })
                  : ''
              }
              inputId={name}
              closeMenuOnSelect={false}
              ref={ref}
              onChange={(val: MultiSelectOptions[]) => {
                onChange(val.map((c) => c.value));
                if (submitOnChange) methods.handleSubmit(submitOnChange)();
              }}
              options={options}
              isMulti
            />
          );
        }}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name as never}
          render={({ message }) => <ValidationErrorMessage>{message}</ValidationErrorMessage>}
        />
      )}
    </FormGroup>
  );
};
