import { Button } from '@team-lightfeather/ui-react-assets';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='width-tablet display-flex flex-column margin-x-auto text-left'>
      <h1>Page Not Found!</h1>
      <h2>We cannot find the page that you&apos;re looking for</h2>
      <p>Check to see if you have typed the correct address</p>
      <Button onClick={() => navigate('/')} className='width-10 margin-top-4'>
        Home
      </Button>
    </div>
  );
};
