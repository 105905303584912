import { Button } from '@team-lightfeather/ui-react-assets';

import { useDeleteLevelApi } from '@/api/Level';

interface DeleteLevelButtonProps {
  levelId: string;
  name: string;
  onClick?: () => void;
}

export const DeleteLevelButton = ({ levelId, name }: DeleteLevelButtonProps) => {
  const { mutate } = useDeleteLevelApi();

  const handleDelete = () => {
    if (confirm(`Delete ${name} level?`)) {
      mutate(levelId);
    }
  };

  return <Button onClick={handleDelete}>Delete</Button>;
};
