import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { SkillQueryKey } from '@/api/queryKeys';
import { apiDelete, apiGet, apiPost, apiPut } from '@/api/utilities';

import {
  CreateSkillParams,
  DeleteSkillParams,
  DeleteSkillResponse,
  GetSkillParams,
  OptimisticSkillContext,
  Skill,
  SkillParams,
  UpdateSkillParams,
  UpdateSkillPayload,
} from './Skill.model';
export const BASE_URL = '/skills';

export const useGetSkillsApi = (params?: SkillParams) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [SkillQueryKey],
    queryFn: () => apiGet<Skill[], SkillParams>(BASE_URL, params),
    onSuccess: async (data) => {
      data.map(async (skill) => {
        queryClient.setQueryData([SkillQueryKey, skill.id], skill);
      });
      queryClient.setQueryData([SkillQueryKey], data);
    },
  });
};

export const useGetSkillApi = ({ id, params }: GetSkillParams) => {
  return useQuery({
    queryKey: [SkillQueryKey, id],
    queryFn: () => apiGet<Skill, SkillParams>(`${BASE_URL}/${id}`, params),
  });
};

export const useCreateSkillApi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateSkillParams) => apiPost<Skill, CreateSkillParams>(BASE_URL, data),
    onSuccess: async (data) => {
      queryClient.setQueryData([SkillQueryKey], (skills: Skill[]) => {
        return [...skills, data];
      });
      queryClient.setQueryData([SkillQueryKey, data.id], data);
    },
  });
};

export const useUpdateSkillApi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onMutate: async ({ id, data }: UpdateSkillParams) => {
      await queryClient.cancelQueries([SkillQueryKey, id]);
      const previousSkill = queryClient.getQueryData([SkillQueryKey, id]);
      queryClient.setQueryData([SkillQueryKey], (skills: Skill[]) => {
        return skills.map((s) => (s.id === id ? { ...data, id } : s));
      });
      queryClient.setQueryData([SkillQueryKey, id], { ...data, id });
      return { previous: previousSkill } as OptimisticSkillContext;
    },

    onError: async (error, variables, context: OptimisticSkillContext) => {
      queryClient.setQueryData([SkillQueryKey, variables.id], context.previous);
      queryClient.setQueryData([SkillQueryKey], (skills: Skill[]) => {
        return skills.map((s) => (s.id === variables.id ? context.previous : s));
      });
    },

    mutationFn: (params: UpdateSkillParams) =>
      apiPut<Skill, UpdateSkillPayload>(`${BASE_URL}/${params.id}`, params.data),

    onSuccess: async (data) => {
      queryClient.setQueryData([SkillQueryKey, data.id], data);
      queryClient.setQueryData([SkillQueryKey], (skills: Skill[]) => {
        return skills.map((s) => (s.id === data.id ? data : s));
      });
    },
  });
};

export const useDeleteSkillApi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: DeleteSkillParams) =>
      apiDelete<DeleteSkillResponse, DeleteSkillParams>(`${BASE_URL}/${params.id}`),
    onSuccess: async (data, { id }) => {
      queryClient.setQueryData([SkillQueryKey], (skills: Skill[]) => {
        return skills.filter((s) => s.id !== id);
      });
      queryClient.removeQueries([SkillQueryKey, id]);
    },
  });
};
