import { Button } from '@team-lightfeather/ui-react-assets';
import { Dispatch, SetStateAction } from 'react';

import { LevelSlider } from '@/features/levels/LevelsManagement';
import { SelectSkills } from '@/features/skills/SelectSkills';

import { RequiredCheck } from './RequiredCheck';
import { SelectionState } from './SearchUserProficiencies';

interface SelectionProps {
  position: 1 | 2 | 3 | 4 | 5 | 6;
  showButtons?: boolean;
  skillRowCount: number;
  setLevelSelection: Dispatch<SetStateAction<SelectionState<number>>>;
  setSkillSelection: Dispatch<SetStateAction<SelectionState<string>>>;
  setRequired: Dispatch<SetStateAction<SelectionState<boolean>>>;
  setSkillRowCount: Dispatch<SetStateAction<number>>;
  // Used for default values (below)
  skillNames?: SelectionState<string> | undefined;
  minLevels?: SelectionState<number> | undefined;
  required?: SelectionState<boolean> | undefined;
}

export const SkillSelectionBlock = ({
  position,
  setLevelSelection,
  setSkillSelection,
  setRequired,
  skillRowCount,
  setSkillRowCount,
  showButtons,
  skillNames = {},
  minLevels = {},
  required = {},
}: SelectionProps) => {
  const handleSkillSelection = (skill: string) => {
    setSkillSelection((prev) => ({
      ...prev,
      [position]: skill,
    }));
  };

  const handleLevelSelection = (level: number) => {
    setLevelSelection((prev) => ({
      ...prev,
      [position]: level,
    }));
  };

  const handleRequiredToggle = (value: boolean) => {
    setRequired((prev) => ({
      ...prev,
      [position]: value,
    }));
  };

  return (
    <div className='grid-row grid-gap-lg flex-align-end'>
      <div className='grid-col-4'>
        <SelectSkills onSelect={handleSkillSelection} defaultValue={skillNames[position]} />
      </div>
      <div className='grid-col-4'>
        <LevelSlider onSelect={handleLevelSelection} defaultValue={minLevels[position]} />
      </div>
      <div className='grid-col-2 margin-bottom-1'>
        <RequiredCheck
          position={position}
          onChange={handleRequiredToggle}
          defaultValue={required[position]}
          disabled={position === 1}
        />
      </div>
      <div className='grid-col-2 grid-row'>
        {showButtons && (
          <>
            {position !== 4 && (
              <Button
                className='bg-green'
                data-testid='add-btn'
                onClick={() => {
                  setSkillRowCount(++skillRowCount);
                }}
              >
                +
              </Button>
            )}
            {position !== 1 && (
              <Button
                className='bg-red'
                data-testid='subtract-btn'
                onClick={() => {
                  setSkillRowCount(--skillRowCount);
                }}
              >
                -
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
