import { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import { CreateLevelDTO } from '@/api/Level';
import { Form, InputField } from '@/components';

const CreateLevelFormSchema: yup.SchemaOf<CreateLevelDTO> = yup.object().shape({
  level: yup.number().required('Level is required'),
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

interface CreateLevelFormProps {
  onSubmit: SubmitHandler<CreateLevelDTO>;
}

export const CreateLevelForm = ({ onSubmit }: CreateLevelFormProps) => {
  const handleSubmit = async (values: CreateLevelDTO) => {
    onSubmit(values);
  };

  return (
    <Form<CreateLevelDTO, typeof CreateLevelFormSchema>
      onSubmit={handleSubmit}
      schema={CreateLevelFormSchema}
    >
      <>
        <h1>Create Proficiency Level</h1>

        <p>To create a Proficiency Level, provide its level number, name and description.</p>

        <InputField<CreateLevelDTO> name='level' label='Proficiency Level' />
        <InputField<CreateLevelDTO> name='name' label='Name' />
        <InputField<CreateLevelDTO> name='description' label='Description' />
      </>
    </Form>
  );
};
