import {
  Alert,
  Button,
  ButtonGroup,
  Modal,
  Table,
  TableData,
} from '@team-lightfeather/ui-react-assets';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CellProps } from 'react-table';

import { CreateLevelDTO, useCreateLevelApi, useGetLevels } from '@/api/Level';
import { Level } from '@/api/Level';
import { CreateLevel } from '@/features/levels/CreateLevel';

import { UpdateLevelModal } from '../UpdateLevel';
import { DeleteLevelButton } from './DeleteLevelButton';

const emptyLevel: Level = {
  id: '',
  level: 0,
  name: '',
  description: '',
};

export const LevelsManagement = () => {
  const [level, setLevel] = useState<Level>(emptyLevel);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const levelQuery = useGetLevels();

  const { mutate: createLevel } = useCreateLevelApi();

  const handleCreateLevelSubmit: SubmitHandler<CreateLevelDTO> = (values: CreateLevelDTO) => {
    createLevel(values);
    setIsCreateModalOpen(false);
  };

  if (levelQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (levelQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving proficiency levels!</Alert.Text>
      </Alert>
    );
  }
  const renderUpdateModal = (level: Level) => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
    setLevel(level);
  };

  const renderCell = ({ cell }: CellProps<Level>) => (
    <>
      <ButtonGroup>
        <Button name='Update' onClick={() => renderUpdateModal(cell.row.original)}></Button>
        <DeleteLevelButton levelId={cell.row.original.id} name={cell.row.original.name} />
      </ButtonGroup>
    </>
  );

  const columns = [
    {
      Header: 'Level',
      accessor: 'level',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: renderCell,
      disableSortBy: true,
    },
  ];

  return (
    <>
      <h2>Levels</h2>
      <div className='right-align'>
        <Button name='Add Level' onClick={() => setIsCreateModalOpen(!isCreateModalOpen)} />
      </div>
      <Modal
        variant='large'
        aria-labelledby='CreateLevelModal'
        open={isCreateModalOpen}
        close={() => setIsCreateModalOpen(false)}
      >
        <CreateLevel onSubmit={handleCreateLevelSubmit} />
      </Modal>
      <UpdateLevelModal
        level={level}
        isModalOpen={isUpdateModalOpen}
        setIsModalOpen={setIsUpdateModalOpen}
      />
      <Table columns={columns} data={levelQuery.data as unknown as TableData[]} sortable />
    </>
  );
};
