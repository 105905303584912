import { rest } from 'msw';

import { GetEducationEnumsDTO, GetUserEducationDTO } from '@/api/Education/Education.model';
import { API_URL } from '@/config';

import {
  mockEducation,
  mockEducationDeleteResponse,
  mockEducationEnumsGetResponse,
  mockEducationGetResponse,
  mockUserEducation,
} from '../resolvers/mockEducation';

export const educationHandlers = [
  rest.get(`${API_URL}/education`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEducationGetResponse));
  }),

  rest.get(`${API_URL}/education/0`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetUserEducationDTO>({
        body: mockUserEducation,
        status: 200,
        success: true,
      })
    );
  }),

  rest.get(`${API_URL}/education/enums/all`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json<GetEducationEnumsDTO>(mockEducationEnumsGetResponse));
  }),

  rest.post(`${API_URL}/education`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEducation));
  }),

  rest.put(`${API_URL}/education/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEducation));
  }),

  rest.delete(`${API_URL}/education/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEducationDeleteResponse));
  }),
];
