import { rest } from 'msw';

import { GetContractTeamDTO, GetContractTeamsSkillsDTO } from '@/api/ContractTeam';
import { API_URL } from '@/config';

import { mockContractTeams, mockContractTeamsGetResponse } from '../resolvers/mockContractTeams';
import { mockSkills } from '../resolvers/mockSkills';

export const contractTeamHandlers = [
  // Test query parameters
  rest.get(`${API_URL}/contract-teams`, (req, res, ctx) => {
    const contract = req.url.searchParams.get('contract');
    const populate = req.url.searchParams.get('$populate');

    if (contract && populate === 'skills') {
      return res(
        ctx.status(200),
        ctx.json<GetContractTeamsSkillsDTO>({
          body: [
            {
              ...mockContractTeams[0],
              skills: mockSkills,
            },
          ],
          status: 200,
          success: true,
        })
      );
    }
    return res(ctx.status(200), ctx.json(mockContractTeamsGetResponse));
  }),

  rest.get(`${API_URL}/contract-teams/1`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetContractTeamDTO>({
        body: mockContractTeams[0],
        status: 200,
        success: true,
      })
    );
  }),

  rest.get(`${API_URL}/contract-teams/2`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetContractTeamDTO>({
        body: mockContractTeams[1],
        status: 200,
        success: true,
      })
    );
  }),

  rest.post(`${API_URL}/contract-teams`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContractTeams[0]));
  }),

  rest.put(`${API_URL}/contract-teams/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContractTeams[0]));
  }),
];
