import { Radio, RadioProps } from '@team-lightfeather/ui-react-assets';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

type RadioInputFieldProps<TFieldValues extends FieldValues> = {
  id?: string;
  name: Path<TFieldValues>;
  rules?: RegisterOptions;
  label: string;
  value: string;
  disabled?: boolean;
} & Omit<RadioProps, 'id'>;

export const RadioInputField = <T extends FieldValues>({
  id,
  name,
  label,
  value,
  disabled,
  rules,
  ...rest
}: RadioInputFieldProps<T>) => {
  const methods = useFormContext<T>(); // retrieve all hook methods
  const { register } = methods;

  return (
    <Radio
      {...rest}
      id={id || name}
      label={label}
      value={value}
      disabled={disabled}
      {...register(name, rules)}
    />
  );
};
