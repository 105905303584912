import { rest } from 'msw';

import { DeleteSkillResponse } from '@/api/Skill';
import { API_URL } from '@/config';
import { mockSkills, mockSkillsGetResponse } from '@/mocks/resolvers/mockSkills';

export const skillHandlers = [
  rest.get(`${API_URL}/skills`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSkillsGetResponse));
  }),

  rest.get(`${API_URL}/skills/:id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        body: mockSkills[0],
        success: true,
        status: 200,
      })
    );
  }),

  rest.put(`${API_URL}/skills/:id`, async (req, res, ctx) => {
    const { id } = req.params;
    if (id === '8') {
      return res(ctx.status(500), ctx.json({ message: 'Error updating skill' }));
    }

    const data = await req.json();
    data.id = id;
    data.updatedAt = new Date().toISOString();
    return res(
      ctx.status(200),
      ctx.json({
        body: data,
        success: true,
        status: 200,
      })
    );
  }),

  rest.post(`${API_URL}/skills`, async (req, res, ctx) => {
    const data = await req.json();
    data.id = 'new-id';
    data.createdAt = new Date().toISOString();
    data.updatedAt = new Date().toISOString();
    return res(
      ctx.status(200),
      ctx.json({
        body: data,
        success: true,
        status: 201,
      })
    );
  }),

  rest.delete(`${API_URL}/skills/:id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(<DeleteSkillResponse>{
        body: {
          message: `Skill with id ${req.params.id} deleted`,
        },
        success: true,
        status: 200,
      })
    );
  }),
];
