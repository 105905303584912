import { Alert } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchUsersProficienciesReqDTO, useSearchUsersProficiencies } from '@/api/Proficiency';
import { SearchResultUser } from '@/api/User';
import { paramProficiencies } from '@/utils/queryParams';

import { ShareSearchBtn } from '../saveSearch/ShareSearchBtn';
import { UserDetailModal } from '../users/UserDetail/UserDetailModal';
import { MatrixUsersList } from './MatrixUsersList';
import { SkillSelectionBlock } from './SkillSelectionBlock';
import { UsersProficiencyList } from './UsersProficiencyList';
import { createReqBody } from './utils/proficiencySearch';

export type SelectionState<T> = {
  1?: T | undefined;
  2?: T | undefined;
  3?: T | undefined;
  [key: number]: T | undefined;
};

interface Props {
  hideHeader?: boolean;
  useQueryParams?: boolean;
  showUserProficiencyList?: boolean;
  searchUsersRes?: SearchResultUser[];
  showShareSearchBtn?: boolean;
  callBack?: (payload: SearchUsersProficienciesReqDTO[]) => void;
}

export const SearchUserProficiencies = ({
  hideHeader,
  useQueryParams = true,
  showUserProficiencyList = true,
  showShareSearchBtn = false,
  searchUsersRes,
  callBack,
}: Props) => {
  const [skillNames, setSkillNames] = useState<SelectionState<string>>();
  const [minLevels, setMinLevels] = useState<SelectionState<number>>();
  const [required, setRequired] = useState<SelectionState<boolean>>();
  const [skillRowCount, setSkillRowCount] = useState<number>(1);
  const [saveSearchPayload, setSaveSearchPayload] = useState<SearchUsersProficienciesReqDTO[]>();
  const [selectedUser, setSelectedUser] = useState<string | undefined>(''); // for modal
  const [searchParams] = useSearchParams();

  const { data, mutate, isError, isLoading } = useSearchUsersProficiencies();

  // Set state from any proficiency params present in url
  useEffect(() => {
    if (!useQueryParams) return;
    const searchProficiencies = paramProficiencies(searchParams);

    searchProficiencies.forEach((prof, i) => {
      const rowCount = i + 1;

      setSkillNames((prev) => ({
        ...prev,
        [rowCount]: prof.skill,
      }));

      setMinLevels((prev) => ({
        ...prev,
        [rowCount]: prof.level,
      }));

      setRequired((prev) => ({
        ...prev,
        [rowCount]: prof.required,
      }));
    });

    searchProficiencies.length && setSkillRowCount(searchProficiencies.length);
  }, [searchParams, useQueryParams]);

  // When skillRowCount is decreased, delete corresponding data in state
  useEffect(() => {
    if (skillRowCount === 1 && !saveSearchPayload) return; // fixes bug with loading saved parmas

    setSkillNames((prev) => {
      if (prev) delete prev[(skillRowCount + 1) as keyof SelectionState<string>];
      return { ...prev };
    });

    setMinLevels((prev) => {
      if (prev) delete prev[(skillRowCount + 1) as keyof SelectionState<number>];
      return { ...prev };
    });

    setRequired((prev) => {
      if (prev) delete prev[(skillRowCount + 1) as keyof SelectionState<boolean>];
      return { ...prev };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillRowCount]);

  // create and send api request
  useEffect(() => {
    if (skillNames) {
      const body = createReqBody(skillNames, minLevels, required);
      setSaveSearchPayload(body);
      // Remove any 'blank' skills with value of '--' from the request
      body.forEach((row, i) => {
        if (row.skill === '--') body.splice(i, 1);
      });

      if (body.length) callBack ? callBack(body) : mutate(body);
    }
  }, [skillNames, minLevels, required, skillRowCount, mutate, callBack]);

  return (
    <>
      <UserDetailModal userId={selectedUser} setIsModalOpen={setSelectedUser} />
      {hideHeader ? '' : <h2>Skills Proficiency List</h2>}
      {showShareSearchBtn && (
        <div className='text-right margin-top-3 margin-bottom-5'>
          <ShareSearchBtn payload={{ proficiencies: saveSearchPayload }} />
        </div>
      )}
      <SkillSelectionBlock
        position={1}
        setLevelSelection={setMinLevels}
        setSkillSelection={setSkillNames}
        setRequired={setRequired}
        skillNames={skillNames}
        minLevels={minLevels}
        required={required}
        skillRowCount={skillRowCount}
        setSkillRowCount={setSkillRowCount}
        showButtons={skillRowCount === 1}
      />
      {skillRowCount > 1 && (
        <SkillSelectionBlock
          position={2}
          setLevelSelection={setMinLevels}
          setSkillSelection={setSkillNames}
          setRequired={setRequired}
          skillNames={skillNames}
          minLevels={minLevels}
          required={required}
          skillRowCount={skillRowCount}
          setSkillRowCount={setSkillRowCount}
          showButtons={skillRowCount === 2}
        />
      )}
      {skillRowCount > 2 && (
        <SkillSelectionBlock
          position={3}
          setLevelSelection={setMinLevels}
          setSkillSelection={setSkillNames}
          setRequired={setRequired}
          skillNames={skillNames}
          minLevels={minLevels}
          required={required}
          skillRowCount={skillRowCount}
          setSkillRowCount={setSkillRowCount}
          showButtons={skillRowCount === 3}
        />
      )}
      {skillRowCount > 3 && (
        <SkillSelectionBlock
          position={4}
          setLevelSelection={setMinLevels}
          setSkillSelection={setSkillNames}
          setRequired={setRequired}
          skillNames={skillNames}
          minLevels={minLevels}
          required={required}
          skillRowCount={skillRowCount}
          setSkillRowCount={setSkillRowCount}
          showButtons={skillRowCount === 4}
        />
      )}
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <Alert type='error'>
          <Alert.Text>Error retrieving Proficiencies!</Alert.Text>
        </Alert>
      ) : (
        // When loaded and no errors - display content
        <>
          {data && showUserProficiencyList && (
            <>
              <h4>Results:</h4>
              <UsersProficiencyList searchResults={data} setSelectedUser={setSelectedUser} />
            </>
          )}
          {searchUsersRes && <MatrixUsersList searchResults={searchUsersRes} />}
        </>
      )}
    </>
  );
};
