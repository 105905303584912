import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from '@/components';
import { AuthCallback } from '@/features/auth/AuthCallback';

const Landing = () => {
  return (
    <Layout>
      <Suspense fallback={<>Loading...</>}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/auth/*',
    element: <AuthCallback />,
  },
];
