import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@team-lightfeather/ui-react-assets';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCreateContractApi } from '@/api/Contract';

import { ContractInputs } from '../../ContractForm';
import { ContractFormWrapper } from '../../ContractFormWrapper';

const ContractSchema: yup.SchemaOf<ContractInputs> = yup.object().shape({
  name: yup.string().required('Contract name is required'),
  description: yup.string().optional(),
  point_of_contact: yup.string().required('Point of contact is required'),
  start_date: yup.string().required('Contract start date is required'),
  end_date: yup.string().required('Contract end date is required'),
  status: yup.string().required('Contract status is required'),
  prime_contractor: yup.string().required('Prime contractor is required'),
});

// eslint-disable-next-line react/prop-types
export const CreateContractForm = () => {
  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      point_of_contact: '',
      start_date: '',
      end_date: '',
      status: '',
      prime_contractor: '',
    },
    resolver: yupResolver(ContractSchema),
  });

  const createContract = useCreateContractApi();

  const handleCreateContractSubmit: SubmitHandler<ContractInputs> = (values: ContractInputs) => {
    createContract.mutate(values);
  };

  const renderButtons = () => {
    return (
      <>
        <Button name='Submit' type='submit' />
      </>
    );
  };

  return (
    <ContractFormWrapper
      heading='Create Contract'
      methods={methods}
      onSubmit={handleCreateContractSubmit}
      renderButtons={renderButtons}
    />
  );
};
