import { Button, Modal } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { CreateSkillParams, Skill, useCreateSkillApi, useUpdateSkillApi } from '@/api/Skill';
import { Form, InputField } from '@/components';
interface SkillModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  skill: Skill | null;
}

export enum SkillEnums {
  TOAST_UPDATE_SUCCESS = 'Skill updated successfully!',
  TOAST_UPDATE_ERROR = 'Error updating skill!',
  TOAST_CREATE_SUCCESS = 'Skill created successfully!',
  TOAST_CREATE_ERROR = 'Error creating skill!',
  NAME_IS_REQUIRED = 'Name is required',
  UPDATE_TITLE = 'Update Skill',
  CREATE_TITLE = 'Add Skill',
}

export const SkillModal = ({ isOpen, setIsOpen, skill }: SkillModalProps) => {
  const [title, setTitle] = useState<string>('');
  const [ariaLabel, setAriaLabel] = useState<string>('');
  const [skillData, setSkillData] = useState<Skill | null>(skill);

  const skillFormSchema: yup.SchemaOf<CreateSkillParams> = yup.object().shape({
    name: yup.string().required(SkillEnums.NAME_IS_REQUIRED),
    description: yup.string(),
  });
  const createSkillsApi = useCreateSkillApi();
  const updateSkillsApi = useUpdateSkillApi();

  const handleOnSubmit = (values: CreateSkillParams) => {
    if (skill) {
      updateSkillsApi.mutate(
        { id: skill.id, data: values },
        {
          onSuccess: () => {
            toast.success(SkillEnums.TOAST_UPDATE_SUCCESS);
          },
          onError: () => {
            toast.error(SkillEnums.TOAST_UPDATE_ERROR);
          },
        }
      );
    } else {
      createSkillsApi.mutate(values, {
        onSuccess: () => {
          toast.success(SkillEnums.TOAST_CREATE_SUCCESS);
        },
        onError: () => {
          toast.error(SkillEnums.TOAST_CREATE_ERROR);
        },
      });
    }
    setIsOpen(false);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (skill) {
      setTitle(SkillEnums.UPDATE_TITLE);
      setAriaLabel('SkillUpdateModal');
      setSkillData(skill);
    } else {
      setTitle(SkillEnums.CREATE_TITLE);
      setAriaLabel('SkillAddModal');
      setSkillData(null);
    }
    return () => {
      setTitle('');
      setAriaLabel('');
      setSkillData(null);
    };
  }, [skill]);

  return (
    <Modal variant='large' aria-labelledby={ariaLabel} open={isOpen} close={handleOnClose}>
      <Form<CreateSkillParams, typeof skillFormSchema>
        onSubmit={handleOnSubmit}
        schema={skillFormSchema}
      >
        <h1>{title}</h1>
        <InputField label='Name' name='name' defaultValue={skillData?.name || ''} />
        <InputField
          label='Description'
          name='description'
          defaultValue={skillData?.description || ''}
        />
        <Button onClick={handleOnClose}>Cancel</Button>
      </Form>
    </Modal>
  );
};
