import {
  DeleteEducationDTO,
  Education,
  EducationEnums,
  GetEducationDTO,
  GetEducationEnumsDTO,
  GetUserEducationDTO,
  UserEducation,
} from '@/api/Education/Education.model';

export const mockEducation: Education = {
  id: '0',
  user: '64d9589d8b3fb932c4ad39b0',
  degrees: [
    {
      level: 'Bachelors',
      field: 'Software Engineering',
      id: '6501ddf44801f18ccec80a9f',
    },
  ],
  certifications: [],
  createdAt: '2023-09-13T16:06:12.070Z',
  updatedAt: '2023-09-13T16:06:12.070Z',
};

export const mockUserEducation: UserEducation = {
  id: '0',
  user: {
    id: '0',
    engagedly_id: '0',
    employee_id: '',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'email@lightfeather.io',
    status: 'Active',
    avatar: '',
    state: '',
    job_title: 'DevOps Engineer',
    joining_date: '',
    contract: '0',
    contract_team: '',
    professional_start_date: '2016-06-01T00:00:00.000Z',
    education: '6501ddf44801f18ccec80a9e',
    createdAt: '2023-07-20T20:20:20.201Z',
    updatedAt: '2023-07-20T20:20:20.201Z',
  },
  degrees: [
    {
      id: '0',
      field: 'Computer Engineering',
      level: 'Masters',
    },
  ],
  certifications: [
    {
      id: '0',
      name: 'AWS Certified Solutions Architect',
    },
  ],
};

export const mockEducationEnums: EducationEnums = {
  degreeLevels: ['Associate', 'Bachelors', 'Masters', 'Doctoral'],
  degreeFields: ['Computer Science', 'Computer Engineering'],
  certificationNames: [
    'AWS Certified Solutions Architect',
    'AWS Certified Developer',
    'AWS Certified SysOps Administrator',
  ],
};

export const mockUserEducationGetResponse: GetUserEducationDTO = {
  body: mockUserEducation,
  status: 200,
  success: true,
};

export const mockEducationGetResponse: GetEducationDTO = {
  body: mockEducation,
  status: 200,
  success: true,
};

export const mockEducationDeleteResponse: DeleteEducationDTO = {
  body: {
    message: `Education with id ${mockEducation.id} deleted`,
  },
  status: 200,
  success: true,
};

export const mockEducationEnumsGetResponse: GetEducationEnumsDTO = {
  body: mockEducationEnums,
  status: 200,
  success: true,
};
