import { useParams } from 'react-router-dom';

import { EditContract } from '../ContractsManagement/UpdateContract/EditContract';

export const ContractEdit = () => {
  const { contractId } = useParams();

  return (
    <div>
      <EditContract contractId={contractId} />
    </div>
  );
};
