import { Alert } from '@team-lightfeather/ui-react-assets';
import { FieldValues } from 'react-hook-form';

import { useGetEducationEnums } from '@/api/Education/EducationApi';
import { DropDownMultiSelectField } from '@/components/DropDownMultiSelectField/DropDownMultiSelectField';

type CertificationDropdownProps = {
  name?: string;
  label?: string;
  handleSubmit?: (data: FieldValues) => void;
};

export const CertificationsDropdownMulti = ({
  name = 'certifications',
  label = 'Certifications',
  handleSubmit,
}: CertificationDropdownProps) => {
  const educationEnumsQuery = useGetEducationEnums();

  if (educationEnumsQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving Certifications!</Alert.Text>
      </Alert>
    );
  }

  if (educationEnumsQuery.isLoading) return <>Loading...</>;

  const certificationEnums = educationEnumsQuery.data.body.certificationNames;

  const certificationOptions = certificationEnums.map((name) => ({ value: name, label: name }));

  return (
    <DropDownMultiSelectField
      options={certificationOptions}
      name={name}
      label={label}
      submitOnChange={handleSubmit}
    />
  );
};
