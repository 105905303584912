import { Link } from 'react-router-dom';

export const EmailLink = ({ email }: { email: string | undefined }) => (
  <Link
    to='#'
    onClick={() => {
      if (email) window.location.href = `mailto:${email}`;
    }}
  >
    {email ?? ''}
  </Link>
);
