import { Alert } from '@team-lightfeather/ui-react-assets';
import { FieldValues } from 'react-hook-form';

import { useGetContracts } from '@/api/Contract';
import { DropDownMultiSelectField } from '@/components/DropDownMultiSelectField/DropDownMultiSelectField';

type ContractsDropdownProps = {
  name?: string;
  label?: string;
  handleSubmit?: (data: FieldValues) => void;
};

export const ContractsDropdownMulti = ({
  name = 'contract',
  label = 'Contract',
  handleSubmit,
}: ContractsDropdownProps) => {
  const contractsQuery = useGetContracts();
  if (contractsQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving Contacts!</Alert.Text>
      </Alert>
    );
  }

  if (contractsQuery.isLoading) return <>Loading...</>;

  const multiSelectContracts = contractsQuery.data.body.map((contract) => ({
    value: contract.id,
    label: contract.name,
  }));

  return (
    <DropDownMultiSelectField
      options={multiSelectContracts}
      name={name}
      label={label}
      submitOnChange={handleSubmit}
    />
  );
};
