import { Table, TableData } from '@team-lightfeather/ui-react-assets';
import { useState } from 'react';

import { Contract } from '@/api/Contract';
import { Certification, Degree } from '@/api/Education';
import { SearchProf, SearchResultUser } from '@/api/User';

import { UserDetailModal } from '../users/UserDetail/UserDetailModal';
import { buildColumns } from './UsersProficiencyList';
import { ProficiencyUser } from './utils/proficiencySearch';

interface MatrixUsersListProps {
  searchResults: SearchResultUser[];
}

function isContract(contract: unknown): contract is Contract {
  return typeof contract === 'object' && contract !== null && 'name' in contract;
}

function mapMatrixSearchResult(result: SearchResultUser | undefined): ProficiencyUser {
  if (!result) return {};

  let data: ProficiencyUser = {
    Name: `${result?.first_name ?? ''} ${result?.last_name ?? ''}`,
  };

  if (isContract(result.contract)) {
    data = { ...data, Contract: result.contract?.name };
  } else {
    data = { ...data, Contract: '' };
  }

  const requiredProficiencies: string[] = [];
  result.required?.map((req: SearchProf) => {
    requiredProficiencies.push(`${req.skill.name} - ${req.level.level}\n`);
  });
  requiredProficiencies.sort();
  let requiredProficienciesString = '';
  requiredProficiencies.map((proficiency) => (requiredProficienciesString += proficiency));
  data = { ...data, Required: requiredProficienciesString };

  const optionalProficiencies: string[] = [];
  result.optional?.map((req: SearchProf) => {
    optionalProficiencies.push(`${req.skill.name} - ${req.level.level}\n`);
  });
  optionalProficiencies.sort();
  let optionalProficienciesString = '';
  optionalProficiencies.map((proficiency) => (optionalProficienciesString += proficiency));
  data = { ...data, Optional: optionalProficienciesString };

  result.education?.degrees.map((degree: Degree) => {
    const degreeLevel = degree.level;
    data = { ...data, Education: degreeLevel };
  });

  data = { ...data, YoE: result.experience };

  const certifications: string[] = [];
  result.education?.certifications.map((certificate: Certification) => {
    certifications.push(certificate.name);
  });
  certifications.sort();
  let certificationsString = '';
  certifications.map((certificate) => (certificationsString += `${certificate};\n`));
  data = { ...data, Certifications: certificationsString };

  // set data.id to users id for use with modal
  data = { ...data, id: result.id };

  return data;
}

export const MatrixUsersList = ({ searchResults }: MatrixUsersListProps) => {
  const [selectedUser, setSelectedUser] = useState<string | undefined>('');

  const data = searchResults.map((result) => mapMatrixSearchResult(result));
  const columns = buildColumns(data, setSelectedUser);

  return (
    <>
      <UserDetailModal userId={selectedUser} setIsModalOpen={setSelectedUser} />

      <h4 className='margin-top-6'>Results:</h4>
      <Table columns={columns} data={data as unknown as TableData[]} sortable />
    </>
  );
};
