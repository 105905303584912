import { Alert, Table, TableData } from '@team-lightfeather/ui-react-assets';
import { Column } from 'react-table';

import { useGetProficiency } from '@/api/Proficiency';
import { sortProficienciesBasic } from '@/features/searchUserProficiencies/utils/proficiencySearch';

const tableColumns: Column<TableData>[] = [
  {
    Header: 'Skill',
    accessor: 'skill.name',
  },
  {
    Header: 'Level',
    accessor: 'level.level',
  },
];

interface Props {
  userId?: string;
}

const UserProficiencyListSimple = ({ userId = '' }: Props) => {
  const { data, isLoading, isError } = useGetProficiency(userId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving proficiencies!</Alert.Text>
      </Alert>
    );
  }

  return (
    <div>
      <Table
        sortable
        columns={tableColumns}
        data={sortProficienciesBasic(data) as unknown as TableData[]}
      />
    </div>
  );
};

export default UserProficiencyListSimple;
