import { Card, Image, Tag } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ContractTeam } from '@/api/ContractTeam';
import { Skill, useGetSkillsApi } from '@/api/Skill';
import { ProgressBar } from '@/components/ProgressBar';
import { TagList } from '@/components/TagList';
import { mockLcats } from '@/mocks/resolvers/mockLcats';

import team_icon from '../../../assets/img/team_icon.png';

interface ContractTeamListCardProps {
  team: ContractTeam;
}

interface TeamMembers {
  total_members: number;
  total_seats: number;
}

export const ContractTeamListCard = ({ team: { name, skills, id } }: ContractTeamListCardProps) => {
  const lcats = mockLcats;
  const [teamSkills, setTeamSkills] = useState<Skill[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMembers>({
    total_members: 0,
    total_seats: 0,
  });

  const skillQuery = useGetSkillsApi();

  useEffect(() => {
    const skillData = skillQuery?.data;
    if (skillQuery.status === 'success' && skillData && skills?.length > 0) {
      setTeamSkills(skillData.filter((skill) => skills.includes(skill.id)));
    }

    if (lcats.length > 0) {
      let total_members = 0;
      let total_seats = 0;
      lcats.map((lcat) => {
        total_members += lcat.filled_seats;
        total_seats += lcat.total_seats;
      });
      setTeamMembers({ total_members, total_seats });
    }

    return () => {
      setTeamSkills([]);
      setTeamMembers({
        total_members: 0,
        total_seats: 0,
      });
    };
  }, [skillQuery.data, skillQuery.status, skills, lcats]);

  return (
    <>
      <Card className='padding-bottom-6 width-full'>
        <Card.Header headerClassName='contract-card-header'>
          <Link className='no-style' to={`/contract-teams/${id}`}>
            <div className='grid-row grid-gap-lg flex-align-start'>
              <div className='grid-col-4'>
                <div className='grid-row'>
                  <h5 className='usa-header grid-col margin-top-0 margin-bottom-1'>{name}</h5>
                </div>
              </div>
            </div>
          </Link>
        </Card.Header>
        <Card.Body className='margin-top-3'>
          <div data-testid='contract-card-body' className='card-item-container'>
            <div className='contract-card-item'>
              <Image alt='contract team photo' src={team_icon} width='150' />
            </div>
            <div className='tag-container margin-left-4'>
              <TagList
                tagNames={teamSkills.map((skill) => skill.name)}
                classNames='contract-tag-list'
              />
            </div>
            <div className='contract-card-item'>
              <ProgressBar
                classNames='margin-bottom-1'
                isCircular
                minValue={0}
                maxValue={teamMembers.total_seats}
                value={teamMembers.total_members}
                valueText={`${teamMembers.total_members}/${teamMembers.total_seats}`}
                titleNode='Team Members'
              />
            </div>
          </div>
          <div className='card-item-container margin-top-6'>
            {lcats.map((lcat) => (
              <div key={lcat.id} className='grid-col-2 margin-top-1'>
                <ProgressBar
                  classNames='margin-bottom-1 maxw-15'
                  isCircular
                  minValue={0}
                  maxValue={lcat.total_seats}
                  value={lcat.filled_seats}
                  valueText={`${lcat.filled_seats}/${lcat.total_seats}`}
                  titleNode={<Tag className='radius-md contract-tag-list'>{lcat.title}</Tag>}
                />
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
