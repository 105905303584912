import { Card } from '@team-lightfeather/ui-react-assets';

import { Contract } from '@/api/Contract';

import { ContractListCard } from '../ContractCards';

interface ContractsListProps {
  contracts: Contract[];
}

export const ContractsList = ({ contracts }: ContractsListProps) => {
  return (
    <>
      <Card.Group>
        {contracts.map((contract) => (
          <ContractListCard key={contract.id} contract={contract} />
        ))}
      </Card.Group>
    </>
  );
};
