import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';
import { queryClient } from '@/lib/react-query';

import {
  ContractTeam,
  CreateContractTeamDTO,
  DeleteContractTeamDTO,
  GetContractTeamDTO,
  GetContractTeamsDTO,
  GetContractTeamsSkillsDTO,
  UpdateContractTeamDTO,
} from './ContractTeam.model';

const getContractTeams = (): Promise<GetContractTeamsDTO> => {
  return api.get('/contract-teams');
};

export const useGetContractTeams = () => {
  return useQuery({
    queryKey: ['contract-team'],
    queryFn: getContractTeams,
  });
};

const getContractTeam = ({
  contractTeamId,
}: {
  contractTeamId: string;
}): Promise<GetContractTeamDTO> => {
  return api.get(`/contract-teams/${contractTeamId}`);
};

export const useGetContractTeam = ({ contractTeamId }: { contractTeamId: string }) => {
  return useQuery({
    queryKey: ['contract-team', contractTeamId],
    queryFn: () => getContractTeam({ contractTeamId }),
  });
};

const createContractTeam = (data: CreateContractTeamDTO): Promise<ContractTeam> => {
  return api.post<ContractTeam, ContractTeam, CreateContractTeamDTO>('/contract-teams', data);
};

export const useCreateContractTeamApi = () => {
  return useMutation({
    mutationFn: createContractTeam,
    onSuccess: () => queryClient.refetchQueries(['contract-team']),
  });
};

const updateContractTeam = ({ id, data }: UpdateContractTeamDTO): Promise<ContractTeam> => {
  return api.put(`/contract-teams/${id}`, data);
};

export const useUpdateContractTeam = () => {
  return useMutation({
    onMutate: async (updatingContractTeam: UpdateContractTeamDTO) => {
      await queryClient.cancelQueries(['contract-team', updatingContractTeam?.id]);

      const previousContractTeam = queryClient.getQueryData<ContractTeam>([
        'contract-team',
        updatingContractTeam?.id,
      ]);

      queryClient.setQueryData(['contract-team', updatingContractTeam?.id], {
        ...previousContractTeam,
        ...updatingContractTeam.data,
        id: updatingContractTeam.id,
      });

      return { previousContractTeam };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, __, context: any) => {
      if (context?.previousContractTeam) {
        queryClient.setQueryData(
          ['contract-team', context.previousContractTeam.id],
          context.previousContractTeam
        );
      }
    },
    onSuccess: () => queryClient.refetchQueries(['contract-team']),
    mutationFn: updateContractTeam,
  });
};

const deleteContractTeam = (id: string): Promise<DeleteContractTeamDTO> => {
  return api.delete(`contract-teams/${id}`);
};

export const useDeleteContractTeamApi = () => {
  return useMutation({
    mutationFn: deleteContractTeam,
    onSuccess: () => queryClient.refetchQueries(['contract-team']),
  });
};

const getContractSkills = async (contractId: string) => {
  const results = await api.get<GetContractTeamsSkillsDTO, GetContractTeamsSkillsDTO>(
    `/contract-teams/?contract=${contractId}&$populate=skills`
  );
  return results.body;
};

export const useGetContractSkills = (contractId: string) => {
  return useQuery({
    queryKey: ['contract-skills', contractId],
    queryFn: async () => await getContractSkills(contractId),
  });
};
