import { GetSkillsResponse, Skill } from '@/api/Skill';

export const mockSkills: Skill[] = [
  { id: '1', createdAt: new Date().toISOString(), name: 'Java', description: 'Java description' },
  {
    id: '2',
    createdAt: new Date().toISOString(),
    name: 'Python',
    description: 'Python description',
  },
  {
    id: '3',
    createdAt: new Date().toISOString(),
    name: 'React',
    description: 'React description',
  },
  {
    id: '4',
    createdAt: new Date().toISOString(),
    name: 'AWS',
    description: 'AWS description',
  },
  {
    id: '5',
    createdAt: new Date().toISOString(),
    name: '.Net',
    description: '.Net description',
  },
  {
    id: '6',
    createdAt: new Date().toISOString(),
    name: 'Golang',
    description: 'Golang description',
  },
  {
    id: '7',
    createdAt: new Date().toISOString(),
    name: 'HTML5',
    description: 'HTML5 description',
  },
  {
    id: '8',
    createdAt: new Date().toISOString(),
    name: 'Oracle',
    description: 'Oracle description',
  },
];

export const mockSkillsGetResponse: GetSkillsResponse = {
  body: mockSkills,
  status: 200,
  success: true,
};
