import { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import { CreateUserDTO } from '@/api/User/User.model';
import { Form, InputField } from '@/components';

const CreateUserFormSchema: yup.SchemaOf<CreateUserDTO> = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Email Address must be valid')
    .required('Email Address is required')
    .matches(/(uscis.dhs.gov|lightfeather.io)$/i, 'Email Address must be a valid USCIS email'),
});

interface CreateUserFormProps {
  onSubmit: SubmitHandler<CreateUserDTO>;
}

export const CreateUserForm = ({ onSubmit }: CreateUserFormProps) => {
  const handleSubmit = async (values: CreateUserDTO) => {
    onSubmit(values);
  };

  return (
    <Form<CreateUserDTO, typeof CreateUserFormSchema>
      onSubmit={handleSubmit}
      schema={CreateUserFormSchema}
    >
      <>
        <h1>Create User</h1>

        <p>To create a user, provide their first name, last name and email address.</p>

        <InputField<CreateUserDTO> name='firstName' label='First Name' />
        <InputField<CreateUserDTO> name='lastName' label='Last Name' />
        <InputField<CreateUserDTO> name='email' label='Email Address' />
      </>
    </Form>
  );
};
