import { Header, HeaderContextProvider, Image } from '@team-lightfeather/ui-react-assets';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { authUrl, logoutUrl } from '@/config';
import { useAuth } from '@/providers/auth';

import lightfeather_logo from '../../assets/img/LightFeather_Logo_2021.png';

interface HeaderLinkProps {
  href: string;
  text: string;
}

export const HeaderLink = ({ href, text }: HeaderLinkProps) => {
  return <Header.Link href='/' text={text} render={(props) => <Link {...props} to={href} />} />;
};

export const Nav = () => {
  const { isAuthenticated } = useAuth();
  const authenticatedNav = () => (
    <>
      {/* TODO: expose render prop for Header Link component to allow using react-router-dom Link component  */}
      <HeaderLink href='/' text='Matrix Search' />

      <HeaderLink href='/contracts' text='Contracts' />

      <HeaderLink href='/users' text='Users' />

      <HeaderLink href='/skills' text='Skills' />

      <HeaderLink href='/levels' text='Levels' />

      <HeaderLink href='/proficiencies/search-proficiencies' text='Search Proficiencies' />

      <Header.Link href={logoutUrl} text='Logout' />
    </>
  );

  return (
    <HeaderContextProvider>
      <nav className='nav-bar'>
        <Link className='no-style' to='/'>
          <div className='nav-logo'>
            <Image alt='contract photo' src={lightfeather_logo} height='50' />
            <p>Skills Matrix</p>
          </div>
        </Link>
        <div className='nav-links'>
          <Header>
            {isAuthenticated ? (
              authenticatedNav()
            ) : (
              <Header.Link href={authUrl} text='Login/Signup' />
            )}
          </Header>
        </div>
      </nav>
    </HeaderContextProvider>
  );
};

type LayoutProps = {
  classNames?: string;
  children: React.ReactNode;
};

export const Layout = ({ children, classNames }: LayoutProps): React.ReactElement => {
  return (
    <div>
      <Nav />
      <main className={classnames('grid-container', classNames)}>
        <div className='margin-top-4'>{children}</div>
      </main>
    </div>
  );
};
