import { axios } from '@/lib/axios';

export default axios;

export type ApiResponse<Type> = {
  success: boolean;
  status: number;
  body: Type;
};

export const apiGet = async <ResponseType, ParamsType>(
  endpoint: string,
  params?: ParamsType
): Promise<ResponseType> => {
  const response = await axios.get<ApiResponse<ResponseType>, ApiResponse<ResponseType>>(endpoint, {
    params,
  });
  return response.body;
};

export const apiPost = async <ResponseType, Payload>(
  endpoint: string,
  data: Payload
): Promise<ResponseType> => {
  const response = await axios.post<ApiResponse<ResponseType>, ApiResponse<ResponseType>>(
    endpoint,
    data
  );
  return response.body;
};

export const apiPut = async <ResponseType, Payload>(
  endpoint: string,
  data: Payload
): Promise<ResponseType> => {
  const response = await axios.put<ApiResponse<ResponseType>, ApiResponse<ResponseType>>(
    endpoint,
    data
  );
  return response.body;
};

export const apiDelete = async <ResponseType, Payload>(
  endpoint: string,
  data?: Payload
): Promise<ResponseType> => {
  const response = await axios.delete<ApiResponse<ResponseType>, ApiResponse<ResponseType>>(
    endpoint,
    { data }
  );
  return response.body;
};
