import { TableData } from '@team-lightfeather/ui-react-assets';
import { Column } from 'react-table';

import {
  DeleteProficiencyDTO,
  GetProficienciesDTO,
  ProficiencyDetailed,
  SearchUsersProficienciesReqDTO,
  SearchUsersProficienciesResData,
  SearchUsersProficienciesResDTO,
} from '@/api/Proficiency';
import { SelectionState } from '@/features/searchUserProficiencies/SearchUserProficiencies';
import { ProficiencyUser } from '@/features/searchUserProficiencies/utils/proficiencySearch';

import { mockLevels } from './mockLevels';
import { mockSkills } from './mockSkills';

export const mockProficiencies: ProficiencyDetailed[] = [
  {
    id: '1',
    user: '1',
    skill: mockSkills[0],
    level: mockLevels[0],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: '2',
    user: '1',
    skill: mockSkills[1],
    level: mockLevels[1],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: '3',
    user: '2',
    skill: mockSkills[2],
    level: mockLevels[2],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
];

export const mockProficienciesGetResponse: GetProficienciesDTO = {
  body: mockProficiencies,
  status: 200,
  success: true,
};

export const levelState: SelectionState<number> = { 1: 3, 2: 2, 3: 4, 4: 5, 5: 2 };
export const requiredState: SelectionState<boolean> = {
  1: true,
  2: true,
  3: false,
  4: false,
  5: false,
};
export const skillState: SelectionState<string> = {
  1: 'programming',
  2: 'design',
  3: 'data analysis',
  4: 'communication',
  5: 'Node.Js',
};

export const searchUsersProficienciesReqArray: SearchUsersProficienciesReqDTO[] = [
  { skill: 'programming', level: 3, required: true },
  { skill: 'design', level: 2, required: true },
  { skill: 'data analysis', level: 4, required: false },
  { skill: 'communication', level: 5, required: false },
  { skill: 'Node.Js', level: 2, required: false },
];

export const searchUsersProficienciesResDTOArr: SearchUsersProficienciesResDTO[] = [
  {
    skill: 'programming',
    level: 3,
  },
  {
    skill: 'design',
    level: 2,
  },
  {
    skill: 'data analysis',
    level: 4,
  },
  {
    skill: 'communication',
    level: 5,
  },
  {
    skill: 'Node.Js',
    level: 2,
  },
];

export const searchUsersProficienciesResData: SearchUsersProficienciesResData = {
  id: '123',
  first_name: 'Joe',
  last_name: 'Smith',
  email: 'joe@smith.com',
  proficiencies: searchUsersProficienciesResDTOArr,
};

export const userData: ProficiencyUser = {
  Name: 'Joe Smith',
  id: '123',
  programming: 3,
  design: 2,
  'data analysis': 4,
  communication: 5,
  NodeJs: 2,
};

export const columnData: Column<TableData>[] = [
  { Header: 'Name', accessor: 'Name' },
  { Header: 'programming', accessor: 'programming' },
  { Header: 'design', accessor: 'design' },
  { Header: 'data analysis', accessor: 'data analysis' },
  { Header: 'communication', accessor: 'communication' },
  { Header: 'NodeJs', accessor: 'NodeJs' },
];

export const mockProficienciesDeleteResponse: DeleteProficiencyDTO = {
  body: {
    message: `Proficiency with id ${mockProficiencies[0].id} deleted`,
  },
  status: 200,
  success: true,
};
