import { Alert, Button } from '@team-lightfeather/ui-react-assets';
import * as yup from 'yup';

import { useUpdateLevelApi } from '@/api/Level';
import { Level, LevelDTO } from '@/api/Level/Level.model';
import { Form, InputField } from '@/components';

export type UpdateFormInputs = { name: string; description: string; level: number };

const UpdateFormSchema: yup.SchemaOf<UpdateFormInputs> = yup.object().shape({
  name: yup.string().required('Skill name is required'),
  level: yup.number().required('Level value is required').typeError('Level value is required'),
  description: yup.string().required(),
});

interface UpdateFormProps {
  level: Level;
  onCancel?: () => void;
  onSuccess?: () => void;
  clearError?: boolean;
}

export const UpdateLevelForm = ({ level, onSuccess, onCancel }: UpdateFormProps) => {
  const { id, name, description, level: levelValue } = level;
  const { isError, mutate } = useUpdateLevelApi();

  const handleSubmit = (values: UpdateFormInputs) => {
    // If onSuccess CB in props, pass it forward
    onSuccess
      ? mutate(
          { data: { ...values, id }, id },
          {
            onSuccess: () => onSuccess(),
          }
        )
      : mutate({ data: { ...values, id }, id });
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <Form<LevelDTO, typeof UpdateFormSchema>
      onSubmit={handleSubmit}
      submitText='Save'
      schema={UpdateFormSchema}
    >
      {isError && (
        <Alert type='error' data-testid='update-error-alert'>
          <Alert.Text>Error updating level!</Alert.Text>
        </Alert>
      )}
      <h3>Edit Level - {name}</h3>
      <InputField name='name' label='Change level name' defaultValue={name} />
      <InputField name='level' type='number' label='Change level value' defaultValue={levelValue} />
      <InputField name='description' label='Change level description' defaultValue={description} />
      <Button onClick={handleCancel}>Cancel</Button>
    </Form>
  );
};
