import { Accordion, Button } from '@team-lightfeather/ui-react-assets';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useGetUserDetailed, UserDetailed } from '@/api/User';
import { EmailLink } from '@/components/Link';

import UserProficiencyListSimple from '../UserProficiencyList/UserProficiencyListSimple';
import { printName } from '../utils';

interface InfoBlockProps {
  title: string;
  children: string | ReactElement | ReactElement[] | undefined;
}
export const InfoBlock = ({ title, children }: InfoBlockProps) => {
  return (
    <div className='text-left'>
      <h3 className='margin-bottom-0'>{children ?? ''}</h3>
      <p className='margin-top-05'>{title}</p>
    </div>
  );
};

interface UserDetailProps {
  proficienciesExpanded?: boolean;
  showEditProficienciesBtn?: boolean;
  userIdProp?: string; // if no userId - get it from query params
}
export const UserDetail = ({
  userIdProp,
  proficienciesExpanded = true,
  showEditProficienciesBtn = true,
}: UserDetailProps) => {
  const { userId = '' } = useParams();

  const [user, setUser] = useState<UserDetailed>();

  const getUserQuery = useGetUserDetailed({ userId: userIdProp ? userIdProp : userId });

  useEffect(() => {
    setUser(getUserQuery.data?.body);
  }, [getUserQuery.data]);

  return (
    <div>
      <h2>User Details</h2>
      <div className='margin-top-6'>
        <div className='grid-row text-left margin-bottom-1'>
          <div className='grid-col'>
            <h1 className='margin-y-0'>{printName(user)}</h1>
          </div>
          <div className='grid-col display-flex flex-align-end'>
            <EmailLink email={user?.email} />
          </div>
        </div>

        <div className='grid-row'>
          <div className='grid-col'>
            <InfoBlock title='Position'>{user?.job_title}</InfoBlock>
          </div>
          <div className='grid-col'>
            <InfoBlock title='Contract'>
              {user?.contract?.name ? (
                user?.contract?.name
              ) : (
                <span className='text-italic '>none</span>
              )}
            </InfoBlock>
          </div>
        </div>

        <div className='grid-row margin-bottom-neg-1'>
          <div className='grid-col'>
            <InfoBlock title='Education'>
              {!user?.education?.degrees?.length ? (
                <span className='text-italic'>none</span>
              ) : (
                user?.education.degrees.map((degree) => (
                  <p className='margin-top-0 margin-bottom-1' key={degree.id}>
                    <span className='text-bold'>{degree.level}</span> - {degree.field}
                  </p>
                ))
              )}
            </InfoBlock>
          </div>
          <div className='grid-col'>
            <InfoBlock title='Certifications'>
              {!user?.education?.certifications?.length ? (
                <span className='text-italic '>none</span>
              ) : (
                user?.education.certifications.map((certification) => (
                  <p className='text-bold margin-top-0 margin-bottom-1' key={certification.id}>
                    {certification.name}
                  </p>
                ))
              )}
            </InfoBlock>
          </div>
        </div>

        <InfoBlock title='Years of Experience'>
          {user?.years_of_experience?.toString() ?? '0'}
        </InfoBlock>
        {showEditProficienciesBtn && (
          <div className='text-right'>
            <Link
              to={`/users/${userId}/edit-proficiencies`}
              className='edit-proficiencies-list_link'
            >
              <Button>Edit proficiencies</Button>
            </Link>
          </div>
        )}
        <div className='margin-top-1'>
          <Accordion expanded={proficienciesExpanded} variant='bordered'>
            <Accordion.Header>
              <h3 className='margin-y-1'>Proficiencies</h3>
            </Accordion.Header>
            <Accordion.Content>
              <div className='margin-y-neg-2'></div>
              {user?.id && <UserProficiencyListSimple userId={user?.id} />}
            </Accordion.Content>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
