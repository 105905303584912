import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';
import { queryClient } from '@/lib/react-query';

import {
  Contract,
  CreateContractDTO,
  DeleteContractDTO,
  GetContractDTO,
  GetContractsDTO,
  UpdateContractDTO,
} from './Contract.model';

const getContracts = (): Promise<GetContractsDTO> => {
  return api.get('/contracts');
};

export const useGetContracts = () => {
  return useQuery({
    queryKey: ['contract'],
    queryFn: getContracts,
  });
};

const getContract = ({ contractId }: { contractId: string }): Promise<GetContractDTO> => {
  return api.get(`/contracts/${contractId}`);
};

export const useGetContract = ({ contractId }: { contractId: string }) => {
  return useQuery({
    queryKey: ['contract', contractId],
    queryFn: () => getContract({ contractId }),
  });
};

const createContract = (data: CreateContractDTO): Promise<Contract> => {
  return api.post<Contract, Contract, CreateContractDTO>('/contracts', data);
};

export const useCreateContractApi = () => {
  return useMutation({
    mutationFn: createContract,
  });
};

export const deleteContract = (id: string): Promise<DeleteContractDTO> => {
  return api.delete(`/contracts/${id}`);
};

export const useDeleteContractApi = () => {
  return useMutation({
    mutationFn: deleteContract,
    onSuccess: () => {
      queryClient.invalidateQueries(['contract']);
    },
  });
};

const updateContract = ({ id, data }: UpdateContractDTO): Promise<Contract> => {
  return api.put(`/contracts/${id}`, data);
};

export const useUpdateContract = () => {
  return useMutation({
    onMutate: async (updatingContract: UpdateContractDTO) => {
      await queryClient.cancelQueries(['contract', updatingContract?.id]);

      const previousContract = queryClient.getQueryData<Contract>([
        'contract',
        updatingContract?.id,
      ]);

      queryClient.setQueryData(['contract', updatingContract?.id], {
        ...previousContract,
        ...updatingContract.data,
        id: updatingContract.id,
      });

      return { previousContract };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, __, context: any) => {
      if (context?.previousContract) {
        queryClient.setQueryData(
          ['contract', context.previousContract.id],
          context.previousContract
        );
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries(['contract']);
    },
    mutationFn: updateContract,
  });
};
