import { SubmitHandler } from 'react-hook-form';

import { CreateLevelDTO } from '@/api/Level';

import { CreateLevelForm } from './CreateLevelForm';

interface CreateLevelFormProps {
  onSubmit: SubmitHandler<CreateLevelDTO>;
}

export const CreateLevel = ({ onSubmit }: CreateLevelFormProps) => {
  return (
    <>
      <CreateLevelForm onSubmit={onSubmit} />
    </>
  );
};
