import { Checkbox } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';

interface RequiredProps {
  position: number;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  defaultValue?: boolean;
}
export const RequiredCheck = ({
  position,
  onChange,
  disabled,
  defaultValue = true,
}: RequiredProps) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  useEffect(() => {
    onChange(isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <Checkbox
      id={`required-${position}`}
      name='required'
      label='Required'
      checked={isChecked}
      onChange={() => setIsChecked(!isChecked)}
      disabled={disabled}
    />
  );
};
