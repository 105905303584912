import { Alert, Button, ButtonGroup, Table, TableData } from '@team-lightfeather/ui-react-assets';
import { useState } from 'react';
import { CellProps, Column } from 'react-table';

import { Skill, useDeleteSkillApi, useGetSkillsApi } from '@/api/Skill';
import { SkillModal } from '@/features/skills/SkillModal';

interface DeleteSkillButtonProps {
  id: string;
}

export const SkillsManagement = () => {
  const [skill, setSkill] = useState<Skill | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const deleteApi = useDeleteSkillApi();
  const skillQuery = useGetSkillsApi();
  if (skillQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (skillQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving skills!</Alert.Text>
      </Alert>
    );
  }

  const handleOnDeleteClick = ({ id }: DeleteSkillButtonProps) => {
    deleteApi.mutate({ id });
  };

  const handleOnSkillCreate = () => {
    setSkill(null);
    setIsModalOpen(true);
  };

  const handleOnSkillEdit = (skill: Skill) => {
    setSkill(skill);
    setIsModalOpen(true);
  };
  const renderCell = ({ cell }: CellProps<Skill>) => (
    <ButtonGroup>
      <Button name='Update' onClick={() => handleOnSkillEdit(cell.row.original)}></Button>
      <Button name='Delete' onClick={() => handleOnDeleteClick(cell.row.original)}></Button>
    </ButtonGroup>
  );

  const columns: Column<TableData>[] = [
    {
      Header: 'Skill',
      accessor: 'name',
      Cell: ({ cell }: CellProps<Skill>) => <span>{`${cell.row.original.name}`}</span>,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: renderCell,
      disableSortBy: true,
    },
  ];

  return (
    <>
      <h2>Skills</h2>
      <div className='right-align'>
        <Button name='Add Skill' onClick={handleOnSkillCreate} />
      </div>
      <SkillModal isOpen={isModalOpen} skill={skill} setIsOpen={setIsModalOpen} />
      <Table columns={columns} data={skillQuery.data as unknown as TableData[]} sortable />
    </>
  );
};
