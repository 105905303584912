import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMessage as ValidationErrorMessage,
  FormGroup,
} from '@team-lightfeather/ui-react-assets';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

type RadioGroupProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  children: React.ReactNode;
};

export const RadioGroup = <T extends FieldValues>({ name, children }: RadioGroupProps<T>) => {
  const methods = useFormContext<T>(); // retrieve all hook methods
  const {
    formState: { errors },
  } = methods;

  return (
    <FormGroup className='lf-radio-group'>
      {children}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name as never}
          render={({ message }) => <ValidationErrorMessage>{message}</ValidationErrorMessage>}
        />
      )}
    </FormGroup>
  );
};
