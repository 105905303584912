import { Level } from '@/api/Level';

export const mockLevels: Level[] = [
  {
    id: '0',
    createdAt: new Date().toISOString(),
    level: 0,
    name: 'Not Proficient',
    description: 'No proficiency',
  },
  {
    id: '1',
    createdAt: new Date().toISOString(),
    level: 1,
    name: 'Basic',
    description: 'Basic proficiency',
  },
  {
    id: '2',
    createdAt: new Date().toISOString(),
    level: 2,
    name: 'Intermediate',
    description: 'Intermediate proficiency',
  },
  {
    id: '3',
    createdAt: new Date().toISOString(),
    level: 3,
    name: 'Advanced',
    description: 'Advanced proficiency',
  },
  {
    id: '4',
    createdAt: new Date().toISOString(),
    level: 4,
    name: 'Expert',
    description: 'Expert proficiency',
  },
];
