import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { ProgressBar } from '../ProgressBar';

interface DateRangeDisplayProps {
  startDate: string;
  endDate: string;
}

export const DateRangeDisplay = ({ startDate, endDate }: DateRangeDisplayProps) => {
  const [progressValue, setProgressValue] = useState<number>(0);

  useEffect(() => {
    if (startDate && endDate) {
      const currentDate = dayjs();
      const startDateObj = dayjs(startDate);
      const endDateObj = dayjs(endDate);
      const dateRange = endDateObj.diff(startDateObj);
      const currentDateDiffStartDate = currentDate.diff(startDateObj);
      const percentageCompleted =
        currentDateDiffStartDate < 0 ? 0 : (currentDateDiffStartDate / dateRange) * 100;
      setProgressValue(Math.floor(percentageCompleted));
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className='grid-row margin-bottom-1'>
        <div className='grid-col-6'>
          <div className='grid-row'>
            <div className='grid-col'>
              <div className='grid-row font-ui-sm'>{dayjs(startDate).format('MM-DD-YYYY')}</div>
              <div className='grid-row'>
                <span className='text-light text-italic font-ui-xs'>Start Date</span>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-col-6'>
          <div className='grid-row'>
            <div className='grid-col progress-bar-end-date'>
              <div className='grid-row grid-row font-ui-sm'>
                {dayjs(endDate).format('MM-DD-YYYY')}
              </div>
              <div className='grid-row'>
                <span className='text-light text-italic font-ui-xs'>End Date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProgressBar maxValue={100} value={progressValue} />
    </>
  );
};
