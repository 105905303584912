import React, { useContext, useState } from 'react';

import storage from '@/utils/storage';

type AuthContextVariables = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  login: (accessToken: string) => void;
  logout: () => void;
};

export type AuthContextValue = AuthContextVariables | null;

export const AuthContext = React.createContext<AuthContextValue>(null);

export const useAuth = (): AuthContextVariables => {
  const auth = useContext(AuthContext);
  if (auth === null) {
    throw new Error('useAuth must be used inside of an AuthProvider');
  }

  return auth;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!storage.getToken());

  const login = (accessToken: string) => {
    storage.setToken(accessToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    storage.clearToken();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
