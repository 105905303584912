import { Modal } from '@team-lightfeather/ui-react-assets';
import { useEffect } from 'react';

import { UserDetail } from './UserDetail';

interface UserDetailModalProps {
  userId: string | undefined;
  setIsModalOpen: (x: string | undefined) => void;
}

export const UserDetailModal = ({ userId, setIsModalOpen }: UserDetailModalProps) => {
  const closeModal = () => {
    setIsModalOpen(undefined);
  };

  useEffect(() => {
    if (!userId) closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Modal
      variant='large'
      aria-labelledby='userDetailModal'
      open={userId ? true : false}
      close={() => closeModal()}
    >
      <div data-testid='userDetailModal'>
        <UserDetail
          userIdProp={userId ?? ''}
          proficienciesExpanded={false}
          showEditProficienciesBtn={false}
        />
      </div>
    </Modal>
  );
};
