import {
  ProficiencyDetailed,
  SearchUsersProficienciesReqDTO,
  SearchUsersProficienciesResData,
  SearchUsersProficienciesResDTO,
} from '@/api/Proficiency';

import { SelectionState } from '../SearchUserProficiencies';

export interface ProficiencyUser {
  [property: string]: string | number | undefined;
}

/**
 * Creates the request body to query the database for list of users with selected proficiencies
 * @param name Object of name values - key name of name and level correspond
 * @param level Object of value levels
 * @returns Formatted request body
 */

export function createReqBody(
  name: SelectionState<string>,
  level: SelectionState<number> | undefined,
  required: SelectionState<boolean> | undefined
): SearchUsersProficienciesReqDTO[] {
  const data: SearchUsersProficienciesReqDTO[] = [];

  const nameEntries = Object.entries(name);

  for (const [key, val] of nameEntries) {
    const levelVal = level && level[+key as keyof SelectionState<number>];
    const requiredVal = required && required[+key as keyof SelectionState<boolean>];
    if (val) {
      data.push({
        skill: val,
        level: levelVal ?? 0,
        required: requiredVal ?? false,
      });
    }
  }
  return data;
}

/**
 * Maps the query results for searching for a list of users with specified proiciencies
 * Mapping the results to a flat User object.
 * The skill names wil be set as the key name - the level will be set as the value to the skill name.
 * @param result The result from the users proficiencies query
 * @returns User object or empty object
 */
export function mapSearchResult(
  result: SearchUsersProficienciesResData | undefined
): ProficiencyUser {
  if (!result) return {};

  let data: ProficiencyUser = {
    Name: `${result?.first_name ?? ''} ${result?.last_name ?? ''}`,
  };
  result.proficiencies.forEach((prof: SearchUsersProficienciesResDTO) => {
    // Strip '.' out of skill name for search reasons
    const skillName = prof.skill.replace('.', '');
    data = { ...data, [skillName]: prof.level, id: result.id };
  });

  return data;
}

export const sortProficienciesBasic = (data: ProficiencyDetailed[] | undefined) => {
  if (!data) return [];

  return data.sort((a, b) => b.level.level - a.level.level);
};
