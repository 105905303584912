import { Input } from '@team-lightfeather/ui-react-assets';
import { useFormContext } from 'react-hook-form';

type YOEProps = {
  name?: string;
  label?: string;
};

export const YearsOfExperience = ({
  name = 'experience',
  label = 'Years of Experience:',
}: YOEProps) => {
  const { register } = useFormContext();

  return (
    <Input
      {...register(name)}
      inputClassName='border-base-light radius-md'
      id='input-experience'
      name={name}
      label={label}
      labelClassName='text-left'
    />
  );
};
