import { Button } from '@team-lightfeather/ui-react-assets';
import { useFormContext } from 'react-hook-form';

import { defaultValues } from './defaultValues';

interface MatrixResetProps {
  onReset?: () => void;
}

export const MatrixReset = ({ onReset }: MatrixResetProps) => {
  const { reset } = useFormContext();

  const resetMatrix = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    onReset && onReset();

    reset(defaultValues);
  };
  return <Button onClick={resetMatrix} className='bg-black' name='RESET' />;
};
