import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';

import {
  CreateUserDTO,
  GetUserDTO,
  GetUsersDTO,
  SearchUsersDTO,
  SearchUsersPayload,
  User,
  UserDetailed,
} from './';

const getUsers = (populate?: string): Promise<GetUsersDTO> => {
  if (populate) {
    return api.get(`/users?%24populate=${populate}`);
  }
  return api.get('/users');
};

export const useGetUsers = (populate?: string) => {
  return useQuery({
    queryKey: ['user', populate],
    queryFn: () => getUsers(populate),
  });
};

const getUser = ({ userId }: { userId: string }): Promise<GetUserDTO> => {
  return api.get(`/users/${userId}`);
};

export const useGetUser = ({ userId }: { userId: string }) => {
  return useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUser({ userId }),
  });
};
const searchUsers = (data: SearchUsersPayload): Promise<SearchUsersDTO> => {
  return api.post('/users/search', {
    contract: data.contract,
    experience: data.experience,
    education: Array.isArray(data.education) ? data.education : [data.education],
    certifications: data.certifications,
    proficiencies: data.proficiencies,
  });
};

export const useSearchUsers = () => {
  return useMutation({
    mutationFn: searchUsers,
  });
};

const getUserDetailed = ({
  userId,
}: {
  userId: string;
}): Promise<{ body: UserDetailed; status: number; success: boolean }> => {
  return api.get(`/users/${userId}?$populate=education,contract`);
};

export const useGetUserDetailed = ({ userId }: { userId: string }) => {
  return useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserDetailed({ userId }),
  });
};

const createUser = (data: CreateUserDTO): Promise<User> => {
  return api.post<User, User, CreateUserDTO>('/users/add', data);
};

export const useCreateUserApi = () => {
  return useMutation({
    mutationFn: createUser,
  });
};
