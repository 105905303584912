import { Tag } from '../Tag/Tag';

interface TagListProps {
  tagNames: string[];
  classNames?: string;
  styles?: { [className: string]: string };
}

export const TagList = ({ tagNames }: TagListProps) => {
  return (
    <>
      <div className='tag-list-container'>
        {tagNames.map((tagName, index) => (
          <div key={index}>
            <Tag tagName={tagName} />
          </div>
        ))}
      </div>
    </>
  );
};
