interface Props {
  title: string;
  clickHandler: () => void;
}

export const PlainButton = ({ title, clickHandler }: Props) => {
  return (
    <button
      className='button-plain'
      onClick={(e) => {
        e.preventDefault();
        clickHandler();
      }}
    >
      {title}
    </button>
  );
};
