import { Alert, Button, Modal } from '@team-lightfeather/ui-react-assets';
import { useState } from 'react';

import { useGetContracts } from '@/api/Contract';

import { ContractsList } from '../ContractsList';
import { CreateContract } from './CreateContract/CreateContract';
export const ContractsManagement = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const contractQuery = useGetContracts();

  if (contractQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (contractQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving contracts!</Alert.Text>
      </Alert>
    );
  }

  return (
    <>
      <h2>Contracts</h2>
      <div className='margin-top-4'>
        <div className='right-align'>
          <Button
            className='margin-bottom-3'
            name='Add Contract'
            onClick={() => setIsCreateModalOpen(!isCreateModalOpen)}
          />
        </div>
        <Modal
          variant='large'
          aria-labelledby='UpdateLevelModal'
          open={isCreateModalOpen}
          close={() => setIsCreateModalOpen(false)}
        >
          <CreateContract />
        </Modal>
        <ContractsList contracts={contractQuery?.data?.body} />
      </div>
    </>
  );
};
