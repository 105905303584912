import { Alert, Card, Image, Tag } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetContract } from '@/api/Contract';
import { useGetContractTeam } from '@/api/ContractTeam';
import { Skill, useGetSkillsApi } from '@/api/Skill';
import { ProgressBar } from '@/components/ProgressBar';
import { TagList } from '@/components/TagList';
import { mockLcats } from '@/mocks/resolvers/mockLcats';

import team_icon from '../../../assets/img/team_icon.png';

interface TeamMembers {
  total_members: number;
  total_seats: number;
}

export const ContractTeamDetailCard = () => {
  const { contractTeamId } = useParams();
  const lcats = mockLcats;
  const [teamSkills, setTeamSkills] = useState<Skill[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMembers>({
    total_members: 0,
    total_seats: 0,
  });

  const skillsQuery = useGetSkillsApi();
  const contractTeamQuery = useGetContractTeam({
    contractTeamId: contractTeamId ? contractTeamId : '',
  });
  const contractQuery = useGetContract({
    contractId: contractTeamQuery.data ? contractTeamQuery.data.body.contract : '',
  });

  useEffect(() => {
    const skillData = skillsQuery.data;
    const contractTeamData = contractTeamQuery?.data?.body;
    if (
      skillsQuery.status === 'success' &&
      skillData &&
      contractTeamQuery.status === 'success' &&
      contractTeamData &&
      contractTeamData.skills?.length > 0
    ) {
      setTeamSkills(skillData.filter((skill) => contractTeamData.skills.includes(skill.id)));
    }

    if (lcats.length > 0) {
      let total_members = 0;
      let total_seats = 0;
      lcats.map((lcat) => {
        total_members += lcat.filled_seats;
        total_seats += lcat.total_seats;
      });
      setTeamMembers({ total_members, total_seats });
    }

    return () => {
      setTeamSkills([]);
      setTeamMembers({
        total_members: 0,
        total_seats: 0,
      });
    };
  }, [
    skillsQuery.data,
    skillsQuery.status,
    contractTeamQuery.status,
    contractTeamQuery.data,
    lcats,
  ]);

  if (contractTeamQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (contractTeamQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving contract team!</Alert.Text>
      </Alert>
    );
  }

  return (
    <>
      <Card.Group>
        <Card className='padding-bottom-6 width-full'>
          <Card.Header headerClassName='contract-card-header'>
            <div className='grid-row grid-gap-lg flex-align-start'>
              <div className='grid-row'>
                <h5 className='usa-header margin-top-05 margin-bottom-1 margin-left-1 margin-right-3'>
                  {contractQuery.data?.body.name}
                </h5>
                <h6 className='usa-header text-no-wrap margin-top-05 margin-bottom-1'>
                  {contractQuery.data?.body.description}
                </h6>
              </div>
            </div>
          </Card.Header>
          <Card.Body className='margin-top-3'>
            <div className='grid-col'>
              <h2 className='text-no-wrap margin-top-0 margin-bottom-1'>
                {contractTeamQuery.data.body.name}
              </h2>
            </div>
            <div data-testid='contract-card-body' className='grid-row'>
              <div className='grid-col-2 margin-top-3'>
                <Image alt='contract team photo' src={team_icon} width='150' />
              </div>
              <div className='grid-col-8 margin-top-2'>
                <TagList
                  tagNames={teamSkills.map((skill) => skill.name)}
                  classNames='contract-tag-list'
                />
              </div>
              <div className='grid-col-2 padding-left-2'>
                <ProgressBar
                  classNames='margin-bottom-1'
                  isCircular
                  minValue={0}
                  maxValue={teamMembers.total_seats}
                  value={teamMembers.total_members}
                  valueText={`${teamMembers.total_members}/${teamMembers.total_seats}`}
                  titleNode='Team Members'
                />
              </div>
            </div>
            <div className='card-item-container margin-top-6'>
              {lcats.map((lcat) => (
                <div key={lcat.id} className='grid-col-2 margin-top-1'>
                  <ProgressBar
                    classNames='margin-bottom-1 maxw-15'
                    isCircular
                    minValue={0}
                    maxValue={lcat.total_seats}
                    value={lcat.filled_seats}
                    valueText={`${lcat.filled_seats}/${lcat.total_seats}`}
                    titleNode={<Tag className='radius-md contract-tag-list'>{lcat.title}</Tag>}
                  />
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </Card.Group>
    </>
  );
};
