import { Image } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';

interface TagProps {
  tagName: string;
  imageUrl?: string;
}

import skill from '../../assets/icons/skill.svg';

const getImage = async (tagName: string) => {
  if (tagName && (tagName.includes('Amazon') || tagName.includes('AWS'))) {
    tagName = 'aws';
  }

  if (tagName && tagName.includes('Angular')) {
    tagName = 'angular';
  }

  let tagNameFirstTen = '';

  if (typeof tagName === 'string') {
    tagNameFirstTen = tagName.replace(/[,.\s]+/g, '');
    tagNameFirstTen = tagNameFirstTen.slice(0, 10).toLowerCase();
  }

  if (tagNameFirstTen && tagNameFirstTen.includes('#')) {
    tagNameFirstTen = tagNameFirstTen.replace(/#/g, '-sharp');
  }

  try {
    const data = await import(`../../assets/icons/${tagNameFirstTen}.png`);
    return data.default;
  } catch (error) {
    return false;
  }
};

export const Tag = ({ tagName, imageUrl }: TagProps) => {
  const [image, setImage] = useState(skill);

  useEffect(() => {
    const checkImage = async (tagName: string) => {
      const data = await getImage(tagName).then((result) => {
        if (result && !imageUrl) {
          return result;
        } else {
          return false;
        }
      });

      if (data) {
        setImage(data);
      }
    };

    checkImage(tagName);
  }, [imageUrl, tagName]);

  return (
    <>
      <div className='tag'>
        <Image alt='skill icon' src={image} height='25' />
        <p>{tagName}</p>
      </div>
    </>
  );
};
