import { MutateOptions } from '@tanstack/react-query';
import { Button } from '@team-lightfeather/ui-react-assets';
import { useNavigate } from 'react-router-dom';

interface DeleteButtonProps {
  id: string;
  buttonText: string;
  name: string;
  category: string;
  navigateToUrl?: string;
  mutateFunction: (
    variables: string,
    options?: MutateOptions<unknown, unknown, string, unknown> | undefined
  ) => void;
  onClick?: () => void;
}

export const DeleteButton = ({
  id,
  buttonText,
  name,
  category,
  navigateToUrl,
  mutateFunction,
}: DeleteButtonProps) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    if (confirm(`Delete ${name} ${category}?`)) {
      mutateFunction(id);
      if (navigateToUrl) navigate(navigateToUrl);
    }
  };

  return <Button onClick={handleDelete}>{buttonText}</Button>;
};
