import { Alert, RangeSlider } from '@team-lightfeather/ui-react-assets';
import React, { useEffect, useState } from 'react';

import { useGetLevels } from '@/api/Level';

interface SliderProps {
  onSelect: (minLevel: number) => void; // Sets minimum skill level
  defaultValue?: number | undefined;
}

const defaultMinValue = 1;

export const LevelSlider = ({ onSelect, defaultValue = defaultMinValue }: SliderProps) => {
  const { data, isError } = useGetLevels();
  const [chosenLevel, setChosenLevel] = useState(defaultMinValue);
  let max = defaultMinValue;

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.valueAsNumber;
    onSelect(val);
    setChosenLevel(val);
  };

  useEffect(() => {
    defaultValue && setChosenLevel(defaultValue);
  }, [defaultValue]);

  if (isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving levels list</Alert.Text>
      </Alert>
    );
  }

  // Set max range to highest skill level
  if (data) {
    data.forEach((n) => {
      if (n.level > max) {
        max = n.level;
      }
    });
  }

  return (
    <RangeSlider
      id='level-minimum-slider'
      data-testid='level-minimum-slider'
      min={1}
      max={max}
      step={1}
      label={`Minimum skill level: ${chosenLevel}`}
      onChange={handleSelect}
      value={chosenLevel}
    />
  );
};
