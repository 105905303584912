import { Alert, Dropdown } from '@team-lightfeather/ui-react-assets';
import React from 'react';

import { Skill } from '@/api/Skill';
import { useGetSkillsApi } from '@/api/Skill';

/*
// Select input component which displays list of skill
// On change the skill id will be passed to cb
*/

interface SelectProps {
  onSelect: (skillName: string) => void;
  defaultValue?: string | undefined;
}

export const SelectSkills = ({ onSelect, defaultValue = '--' }: SelectProps) => {
  const { data, isLoading, isError } = useGetSkillsApi();

  // Match for default skill
  const matchSkillValue = () => {
    let val = '--'; // default value if no match
    if (!defaultValue) return val;

    data?.forEach((skill) => {
      if (skill.name.toLowerCase() === defaultValue.toLowerCase()) {
        val = skill.name;
        return;
      }
    });
    return val;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
  };

  if (isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving skills list</Alert.Text>
      </Alert>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Dropdown onChange={handleChange} label='Choose a skill' defaultValue={matchSkillValue()}>
      <option key={0} value='--'>
        --
      </option>
      {data
        .sort((a: Skill, b: Skill) => {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
        })
        .map((v: Skill) => (
          <option key={v.id} value={v.name} data-testid='select-option'>
            {v.name}
          </option>
        ))}
    </Dropdown>
  );
};
