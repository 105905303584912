import { EditContractForm } from './EditContractForm';

interface EditContractProps {
  contractId: string | undefined;
}

export const EditContract = ({ contractId }: EditContractProps) => {
  return (
    <div>
      <EditContractForm contractId={contractId} />
    </div>
  );
};
