import { Card, Image } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Contract } from '@/api/Contract';
import { useGetContractTeams } from '@/api/ContractTeam';
import { Skill, useGetSkillsApi } from '@/api/Skill';
import { DateRangeDisplay } from '@/components/DateRangeDisplay';
import { ProgressBar } from '@/components/ProgressBar';
import { TagList } from '@/components/TagList';

import us_flag_shield from '../../../assets/img/us_flag_shield.png';

interface ContractListCardProps {
  contract: Contract;
}

export const ContractListCard = ({
  contract: { id, name, description, start_date, end_date },
}: ContractListCardProps) => {
  const [skills, setSkills] = useState<Skill[]>([]);

  const contractTeamQuery = useGetContractTeams();
  const skillQuery = useGetSkillsApi();

  useEffect(() => {
    const contractTeamData = contractTeamQuery?.data?.body;
    const skillData = skillQuery.data;
    if (
      contractTeamQuery.status === 'success' &&
      contractTeamData &&
      skillQuery.status === 'success' &&
      skillData
    ) {
      let allContractSkillIds: string[] = [];
      contractTeamData.forEach(
        (contractTeam) => (allContractSkillIds = allContractSkillIds.concat(contractTeam.skills))
      );
      setSkills(skillData.filter((skill) => allContractSkillIds.includes(skill.id)));
    }

    return () => {
      setSkills([]);
    };
  }, [contractTeamQuery.data, contractTeamQuery.status, skillQuery.data, skillQuery.status]);

  return (
    <>
      <Card className='padding-bottom-6 width-full'>
        <Card.Header headerClassName='contract-card-header'>
          <Link className='no-style' to={`/contracts/${id}`}>
            <div className='grid-row grid-gap-lg flex-align-start'>
              <div className='grid-row'>
                <h5 className='usa-header margin-top-0 margin-bottom-1 margin-left-1 margin-right-5'>
                  {name}
                </h5>
                <h6 className='usa-header text-no-wrap margin-top-0 margin-bottom-1'>
                  {description}
                </h6>
              </div>
            </div>
          </Link>
        </Card.Header>
        <Card.Body className='margin-top-3'>
          <div data-testid='contract-card-body' className='grid-row'>
            <div className='grid-col-2'>
              <Image alt='contract photo' src={us_flag_shield} width='200' />
            </div>
            <div className='grid-col-8'>
              <div className='grid-row'>
                <TagList
                  tagNames={skills.map((skill) => skill.name)}
                  classNames='contract-tag-list'
                />
              </div>
            </div>
            <div className='grid-col-2 padding-left-2'>
              <ProgressBar
                classNames='margin-bottom-1'
                isCircular
                minValue={0}
                maxValue={100}
                value={82}
                valueText='82/100'
                titleNode='Team Members'
              />
            </div>
          </div>
          <div className='margin-top-2'>
            <DateRangeDisplay startDate={start_date} endDate={end_date} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
