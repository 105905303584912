import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@team-lightfeather/ui-react-assets';
import classNamesFn from 'classnames';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  UseFormProps,
} from 'react-hook-form';
import * as yup from 'yup';

interface FormProps<TFormValues extends FieldValues, Schema> {
  classNames?: string;
  onSubmit: SubmitHandler<TFormValues>;
  children: React.ReactNode;
  options?: UseFormProps<TFormValues>;
  submitText?: string;
  submitOnChange?: boolean;
  hideSubmitBtn?: boolean;
  id?: string;
  schema?: Schema;
  defaultValues?: UnpackNestedValue<DeepPartial<TFormValues>>;
}

export const Form = <
  TFormValues extends Record<string, unknown>,
  Schema extends yup.AnyObjectSchema
>({
  children,
  onSubmit,
  schema,
  options,
  submitText = 'Submit',
  submitOnChange,
  hideSubmitBtn,
  id,
  defaultValues,
  classNames,
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: schema && yupResolver(schema),
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        className={classNamesFn('usa-form lf-form text-left usa-form--large', classNames)}
        onSubmit={methods.handleSubmit(onSubmit)}
        onChange={submitOnChange ? methods.handleSubmit(onSubmit) : undefined}
      >
        {children}

        {!hideSubmitBtn && (
          <Button type='submit' className='w-full'>
            {submitText}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};
