import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';
import { queryClient } from '@/lib/react-query';

import {
  CreateEducationDTO,
  DeleteEducationDTO,
  Education,
  GetEducationDTO,
  GetEducationEnumsDTO,
  GetUserEducationDTO,
  UpdateEducationDTO,
} from './Education.model';

const getEducation = (): Promise<GetEducationDTO> => {
  return api.get('/education');
};

export const useGetEducation = () => {
  return useQuery({
    queryKey: ['education'],
    queryFn: getEducation,
  });
};

const getUserEducation = ({
  educationId,
}: {
  educationId: string;
}): Promise<GetUserEducationDTO> => {
  return api.get(`/education/${educationId}`);
};

export const useGetUserEducation = ({ educationId }: { educationId: string }) => {
  return useQuery({
    queryKey: ['education', educationId],
    queryFn: () => getUserEducation({ educationId }),
  });
};

const createEducation = (data: CreateEducationDTO): Promise<Education> => {
  return api.post<Education, Education, CreateEducationDTO>('/education', data);
};

export const useCreateEducationApi = () => {
  return useMutation({
    mutationFn: createEducation,
  });
};

const updateEducation = ({ id, data }: UpdateEducationDTO): Promise<Education> => {
  return api.put(`/education/${id}`, data);
};

export const useUpdateEducation = () => {
  return useMutation({
    onMutate: async (updatingEducation: UpdateEducationDTO) => {
      await queryClient.cancelQueries(['education', updatingEducation?.id]);

      const previousEducation = queryClient.getQueryData<Education>([
        'education',
        updatingEducation?.id,
      ]);

      queryClient.setQueryData(['education', updatingEducation?.id], {
        ...previousEducation,
        ...updatingEducation.data,
        id: updatingEducation.id,
      });

      return { previousEducation };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, __, context: any) => {
      if (context?.previousEducation) {
        queryClient.setQueryData(
          ['education', context.previousEducation.id],
          context.previousEducation
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['education', data.id]);
    },
    mutationFn: updateEducation,
  });
};

const deleteEducation = (id: string): Promise<DeleteEducationDTO> => {
  return api.delete(`education/${id}`);
};

export const useDeleteEducationApi = () => {
  return useMutation({
    mutationFn: deleteEducation,
    onSuccess: () => {
      queryClient.invalidateQueries(['education']);
    },
  });
};

const getEducationEnums = (): Promise<GetEducationEnumsDTO> => {
  return api.get('/education/enums/all');
};

export const useGetEducationEnums = () => {
  return useQuery({
    queryKey: ['education-enums'],
    queryFn: getEducationEnums,
  });
};
