import { Alert, Button, Card, Input, Label } from '@team-lightfeather/ui-react-assets';
import { Controller, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import Select from 'react-select';

import { useGetSkillsApi } from '@/api/Skill';

export type ContractTeamInputs = {
  name: string;
  skills: string[];
};

interface ContractTeamFormProps {
  heading: string;
  onSubmit: SubmitHandler<ContractTeamInputs>;
  methods: UseFormReturn<
    {
      name: string;
      skills: string[];
    },
    ContractTeamInputs
  >;
  defaultValue?: skillOption[];
}

export type skillOption = {
  value: string;
  label: string;
};

// eslint-disable-next-line react/prop-types
export const ContractTeamForm = ({
  heading,
  methods,
  onSubmit,
  defaultValue,
}: ContractTeamFormProps) => {
  const skillsQuery = useGetSkillsApi();

  if (skillsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (skillsQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving skills!</Alert.Text>
      </Alert>
    );
  }

  const skillOptions: skillOption[] = [];
  skillsQuery.data
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    .map((skill) => {
      skillOptions.push({ value: skill.id, label: skill.name });
    });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card.Group>
          <Card className='width-full text-left'>
            <Card.Header headingClassName='font-heading-sm'>{heading}</Card.Header>
            <Card.Body className='grid-container width-full'>
              <div className='grid-row'>
                <div className='grid-col'>
                  <Label>
                    <Input
                      {...methods.register('name')}
                      id='name'
                      name='name'
                      type='text'
                      label='Team Name'
                      hideLabel
                      placeholder='Team Name'
                    />
                    Team Name
                  </Label>
                  {methods.formState.errors.name ? (
                    <p className='usa-error-message'>{methods.formState.errors.name?.message}</p>
                  ) : (
                    ''
                  )}
                  <br />
                  <Controller
                    control={methods.control}
                    name='skills'
                    render={({ field: { onChange, ref } }) => (
                      <Label>
                        <Select
                          className='margin-top-1'
                          closeMenuOnSelect={false}
                          defaultValue={defaultValue}
                          ref={ref}
                          onChange={(val) => onChange(val.map((c) => c.value))}
                          options={skillOptions}
                          isMulti
                        />
                        Team Skills
                      </Label>
                    )}
                  />
                  {methods.formState.errors.skills ? (
                    <p className='usa-error-message'>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {(methods.formState.errors.skills as any)?.message}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Card.Body>
            <Card.Footer className='display-flex flex-justify-center'>
              <Button name='Submit' type='submit' />
            </Card.Footer>
          </Card>
        </Card.Group>
      </form>
    </FormProvider>
  );
};
