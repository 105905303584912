import { Lcat } from '@/api/Lcat';

export const mockLcats: Lcat[] = [
  {
    id: '1',
    title: 'Developer III',
    description: 'Cool devops engineer',
    education: 'BS in Computer Science',
    experience: 5,
    certifications: [
      'AWS Certified Solutions Architect – Associate',
      'AWS Certified Developer – Associate',
    ],
    rate: 125.5,
    contract_id: '1',
    filled_seats: 7,
    total_seats: 8,
  },
  {
    id: '2',
    title: 'DEVSECOPS V',
    description: 'Super cool devops engineer',
    education: 'BS in Computer Science',
    experience: 10,
    certifications: [
      'AWS Certified Solutions Architect – Associate',
      'AWS Certified Developer – Associate',
      'AWS Certified SysOps Administrator – Associate',
    ],
    rate: 150.5,
    contract_id: '1',
    filled_seats: 2,
    total_seats: 3,
  },
  {
    id: '3',
    title: 'QUALITY ENG III',
    description: 'Super cool devops engineer',
    education: 'BS in Computer Science',
    experience: 10,
    certifications: [
      'AWS Certified Solutions Architect – Associate',
      'AWS Certified Developer – Associate',
      'AWS Certified SysOps Administrator – Associate',
    ],
    rate: 150.5,
    contract_id: '1',
    filled_seats: 5,
    total_seats: 5,
  },
  {
    id: '4',
    title: 'SCRUM MASTER I',
    description: 'Super cool devops engineer',
    education: 'BS in Computer Science',
    experience: 10,
    certifications: [
      'AWS Certified Solutions Architect – Associate',
      'AWS Certified Developer – Associate',
      'AWS Certified SysOps Administrator – Associate',
    ],
    rate: 10.5,
    contract_id: '1',
    filled_seats: 1,
    total_seats: 1,
  },
  {
    id: '5',
    title: 'BUSINESS ANALYST III',
    description: 'Super cool devops engineer',
    education: 'BS in Computer Science',
    experience: 10,
    certifications: [
      'AWS Certified Solutions Architect – Associate',
      'AWS Certified Developer – Associate',
      'AWS Certified SysOps Administrator – Associate',
    ],
    rate: 10.5,
    contract_id: '1',
    filled_seats: 3,
    total_seats: 3,
  },
];
