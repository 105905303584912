import { GetUsersDTO, SearchUsersDTO, User } from '@/api/User';

export const mockUsers: User[] = [
  {
    id: '1',
    createdAt: new Date().toISOString(),
    first_name: 'Rhaenyra',
    last_name: 'Targaryen',
    email: 'rhaenyra@targaryen.com',
    trained_coding_challenges: true,
    trained_technical_interviews: true,
  },
  {
    id: '2',
    createdAt: new Date().toISOString(),
    first_name: 'Alicent',
    last_name: 'Hightower',
    email: 'alicent@hightower.com',
  },
];

export const mockUsersSearch: SearchUsersDTO = {
  body: [
    {
      id: '64d9589d8b3fb932c4ad39ac',
      engagedly_id: '713c3f06-5cf3-4141-9666-625efa81579b',
      employee_id: '',
      first_name: 'Alexander',
      last_name: 'Maricich',
      email: 'alexander.maricich@lightfeather.io',
      status: 'Active',
      avatar: '',
      state: '',
      job_title: 'Full Stack Engineer',
      joining_date: '',
      trained_coding_challenges: true,
      trained_technical_interviews: false,
      contract: {
        id: '64f896fda8d6564d36154863',
        name: 'ICE-ERO',
        description:
          'The team of ERO - SWB. Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        point_of_contact: 'Jane Doe',
        start_date: '2023-08-03T17:38:56.722Z',
        end_date: '2025-05-30T18:26:03.690Z',
        status: 'ACTIVE',
        prime_contractor: 'Xebas',
        teams: [],
        createdAt: '2023-08-06T15:13:01.312Z',
        updatedAt: '2023-08-06T15:13:01.312Z',
      },
      contract_team: '',
      professional_start_date: '2012-01-01T00:00:00.000Z',
      education: {
        id: '6501de304801f18ccec80aaa',
        user: '64d9589d8b3fb932c4ad39ac',
        degrees: [
          {
            level: 'Bachelors',
            field: 'Software and Internet Applications',
            id: '6501de304801f18ccec80aab',
          },
        ],
        certifications: [
          {
            name: 'Certified Scrum Master',
            id: '6501e06c4801f18ccec80af0',
          },
        ],
        createdAt: '2023-09-13T16:07:12.464Z',
        updatedAt: '2023-09-13T16:07:12.464Z',
      },
      createdAt: '2023-07-20T20:20:20.201Z',
      updatedAt: '2023-07-20T20:20:20.201Z',

      experience: 11,
      required: [
        {
          id: '64da5852b7cf0cbaaea19392',
          user: '64d9589d8b3fb932c4ad39ac',
          skill: {
            id: '64d955726e60d9805e94d6b1',
            name: 'Node.js',
          },
          level: {
            id: '64da2224aa3550d82ef891fd',
            name: 'Expert',
            level: 5,
          },
          createdAt: '2023-08-14T16:37:38.276Z',
          updatedAt: '2023-08-14T16:37:38.276Z',
        },
        {
          id: '64da5852b7cf0cbaaea19397',
          user: '64d9589d8b3fb932c4ad39ac',
          skill: {
            id: '64d955726e60d9805e94d6b2',
            name: 'React',
          },
          level: {
            id: '64da2224aa3550d82ef891fd',
            name: 'Expert',
            level: 5,
          },
          createdAt: '2023-08-14T16:37:38.278Z',
          updatedAt: '2023-08-14T16:37:38.278Z',
        },
      ],
      optional: [],
    },
  ],
  success: true,
  status: 200,
};

export const mockUsersGetResponse: GetUsersDTO = {
  body: mockUsers,
  status: 200,
  success: true,
};
