import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';
import { queryClient } from '@/lib/react-query';

import {
  CreateProficiencyDTO,
  DeleteProficiencyDTO,
  GetProficienciesDetailedDTO,
  GetProficienciesDTO,
  Proficiency,
  ProficiencyDetailed,
  ProficiencyNameLevel,
  ProficiencyNameLevelDTO,
  SearchUsersProficienciesReqDTO,
  SearchUsersProficienciesRes,
  SearchUsersProficienciesResData,
  UpdateProficiencyDTO,
} from './Proficiency.model';

const getProficiencies = (): Promise<GetProficienciesDTO> => {
  return api.get('/proficiencies');
};

const createProficiency = (data: CreateProficiencyDTO): Promise<Proficiency> => {
  return api.post<Proficiency, Proficiency>('/proficiencies', {
    user: data.user_id,
    skill: data.skill_id,
    level: data.level_id,
  });
};

const updateProficiency = ({ id, data }: UpdateProficiencyDTO): Promise<Proficiency> => {
  return api.put(`/proficiencies/${id}`, {
    user: data.user_id,
    skill: data.skill_id,
    level: data.level_id,
  });
};

const deleteProficiency = (id: string): Promise<DeleteProficiencyDTO> => {
  return api.delete(`/proficiencies/${id}`);
};

const getProficiency = async (userId: string): Promise<ProficiencyDetailed[]> => {
  const data = await api.get<ProficiencyDetailed, GetProficienciesDetailedDTO>(
    `/proficiencies?user=${userId}` + '&$populate=skill,level'
  );
  return data.body;
};

export const useGetProficiency = (userId: string) => {
  return useQuery({
    queryKey: ['proficiency', userId],
    queryFn: async () => {
      return await getProficiency(userId);
    },
  });
};

export const useGetProficiencies = () => {
  return useQuery({
    queryKey: ['proficiencies'],
    queryFn: async () => {
      const data = await getProficiencies();
      return data.body;
    },
  });
};

export const useCreateProficiencyApi = () => {
  return useMutation({
    mutationFn: createProficiency,
    onSuccess: () => {
      queryClient.refetchQueries(['proficiency']);
    },
  });
};

// cache invalidation examples
export const useUpdateProficiencyApi = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.refetchQueries(['proficiency']);
    },
    mutationFn: updateProficiency,
  });
};

// Returns a list of users who have the requested skill id
export const proficiencySearchSkillId = async (
  skillId: string | undefined
): Promise<ProficiencyNameLevel[]> => {
  const response = await api.get<ProficiencyNameLevelDTO, ProficiencyNameLevelDTO>(
    `/proficiencies?$sort=level.level:DESC,user.last_name
    &$exclude=skill_id,level_id,user_id,createdAt
    &$populate=user&$include=user.first_name,user.last_name,skill.name,level.level
    &level.level=$gte:1&skill_id=${skillId}#`
  );
  return response.body;
};

export const useProficiencySearchSkillId = (skillId: string | undefined) => {
  return useQuery({
    queryKey: ['proficiencySearch', skillId],
    queryFn: async () => proficiencySearchSkillId(skillId),
  });
};

// Returns a list of users who have the requested proficiences
const searchUsersProficiencies = async (
  reqBody: SearchUsersProficienciesReqDTO[]
): Promise<SearchUsersProficienciesResData[]> => {
  const response = await api.post<SearchUsersProficienciesResData[], SearchUsersProficienciesRes>(
    '/proficiencies/find-user-proficiencies',
    reqBody
  );
  return response.body;
};

export const useSearchUsersProficiencies = () => {
  return useMutation({
    mutationKey: ['proficiencySearch'],
    mutationFn: searchUsersProficiencies,
  });
};

export const useDeleteProficiencyApi = () => {
  return useMutation({
    mutationFn: deleteProficiency,
    onSuccess: () => {
      queryClient.refetchQueries(['proficiency']);
    },
  });
};
