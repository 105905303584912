import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/providers/auth';

export const LogoutCallback = () => {
  const navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    logout();
    navigate('/');
  }, [logout, navigate]);

  return <></>;
};
