import { useMutation, useQuery } from '@tanstack/react-query';

import api from '@/api';
import { queryClient } from '@/lib/react-query';

import { CreateLevelDTO, GetLevelDTO, GetLevelsDTO, Level, UpdateLevelDTO } from './Level.model';

const getLevels = (): Promise<GetLevelsDTO> => {
  return api.get('/levels');
};

export const useGetLevels = () => {
  return useQuery({
    queryKey: ['level'],
    queryFn: async () => {
      const data = await getLevels();
      return data.body;
    },
  });
};

const createLevel = (data: CreateLevelDTO): Promise<Level> => {
  return api.post<Level, Level, CreateLevelDTO>('/levels', data);
};

const deleteLevel = (id: string): Promise<Level> => {
  return api.delete(`/levels/${id}`);
};

export const useCreateLevelApi = () => {
  return useMutation({
    mutationFn: createLevel,
  });
};

export const useDeleteLevelApi = () => {
  return useMutation({
    mutationFn: deleteLevel,
    onSuccess: () => {
      queryClient.invalidateQueries(['level']);
    },
  });
};

const updateLevel = async ({ id, data }: UpdateLevelDTO): Promise<Level> => {
  const results = await api.put<GetLevelDTO, GetLevelDTO>(`/levels/${id}`, data);
  return results.body;
};

export const useUpdateLevelApi = () => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['level'] });
    },
    mutationFn: updateLevel,
  });
};
