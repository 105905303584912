import { Alert } from '@team-lightfeather/ui-react-assets';

import { useGetEducationEnums } from '@/api/Education/EducationApi';
import { DropdownField } from '@/components';

type EducationDropdownProps = {
  name?: string;
  label?: string;
};

export const EducationDropdown = ({
  name = 'education_level',
  label = 'Education',
}: EducationDropdownProps) => {
  const educationEnumsQuery = useGetEducationEnums();

  if (educationEnumsQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving Education Levels!</Alert.Text>
      </Alert>
    );
  }

  if (educationEnumsQuery.isLoading) return <>Loading...</>;

  const certificationEnums = educationEnumsQuery.data.body.degreeLevels;

  return (
    <DropdownField name={name} label={label}>
      <option value=''>Select...</option>
      {certificationEnums.map((level) => (
        <option key={level} value={level}>
          {level}
        </option>
      ))}
    </DropdownField>
  );
};
