import { Card, Dropdown, Input, Label } from '@team-lightfeather/ui-react-assets';
import { ReactNode } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

const labelClassStyle = 'margin-top-0 margin-bottom-105';

export type ContractInputs = {
  name: string;
  description?: string;
  point_of_contact: string;
  start_date: string;
  end_date: string;
  status: string;
  prime_contractor: string;
};
interface ContractFormProps {
  heading: string;
  onSubmit: SubmitHandler<ContractInputs>;
  methods: UseFormReturn<
    {
      name: string;
      description: string;
      point_of_contact: string;
      start_date: string;
      end_date: string;
      status: string;
      prime_contractor: string;
    },
    ContractInputs
  >;
  renderButtons: () => ReactNode;
}

// eslint-disable-next-line react/prop-types
export const ContractForm = ({ heading, methods, onSubmit, renderButtons }: ContractFormProps) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card.Group>
          <Card className='width-full text-left'>
            <Card.Header headingClassName='font-heading-sm'>{heading}</Card.Header>
            <Card.Body className='grid-container width-full'>
              <div className='grid-row'>
                <div className='grid-col margin-right-105'>
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('name')}
                      id='name'
                      name='name'
                      type='text'
                      label='Contract Name'
                      hideLabel
                      placeholder='Contract Name'
                    />
                    Name
                  </Label>
                  {methods.formState.errors.name ? (
                    <p className='usa-error-message'>{methods.formState.errors.name?.message}</p>
                  ) : (
                    ''
                  )}
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('description')}
                      id='description'
                      name='description'
                      type='text'
                      label='Description'
                      hideLabel
                      placeholder='Description'
                    />
                    Description
                  </Label>
                  {methods.formState.errors.description ? (
                    <p className='usa-error-message'>
                      {methods.formState.errors.description?.message}
                    </p>
                  ) : (
                    ''
                  )}
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('point_of_contact')}
                      id='point_of_contact'
                      name='point_of_contact'
                      type='text'
                      label='Point of Contact'
                      hideLabel
                      placeholder='Point of Contact'
                    />
                    Point of Contact
                  </Label>
                  {methods.formState.errors.point_of_contact ? (
                    <p className='usa-error-message'>
                      {methods.formState.errors.point_of_contact?.message}
                    </p>
                  ) : (
                    ''
                  )}
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('prime_contractor')}
                      id='prime_contractor'
                      name='prime_contractor'
                      type='text'
                      label='Prime Contractor'
                      hideLabel
                      placeholder='Prime Contractor'
                    />
                    Prime Contractor
                  </Label>
                  {methods.formState.errors.prime_contractor ? (
                    <p className='usa-error-message'>
                      {methods.formState.errors.prime_contractor?.message}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className='grid-col margin-left-105'>
                  <Label className={labelClassStyle}>
                    <Dropdown
                      aria-label='ContractStatusDropdown'
                      data-testid='status-dropdown'
                      id='status-dropdown'
                      {...methods.register('status')}
                    >
                      <option value=''>Status</option>
                      <option value='ACTIVE'>ACTIVE</option>
                      <option value='INACTIVE'>INACTIVE</option>
                      <option value='PENDING'>PENDING</option>
                    </Dropdown>
                    Status
                  </Label>
                  {methods.formState.errors.status ? (
                    <p className='usa-error-message'>{methods.formState.errors.status?.message}</p>
                  ) : (
                    ''
                  )}
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('start_date')}
                      id='start_date'
                      name='start_date'
                      type='date'
                      label='Start Date'
                      hideLabel
                      placeholder='Start Date'
                      data-testid='start_date'
                    />
                    Start Date
                  </Label>
                  {methods.formState.errors.start_date ? (
                    <p className='usa-error-message'>
                      {methods.formState.errors.start_date?.message}
                    </p>
                  ) : (
                    ''
                  )}
                  <Label className={labelClassStyle}>
                    <Input
                      {...methods.register('end_date')}
                      id='end_date'
                      name='end_date'
                      type='date'
                      label='End Date'
                      hideLabel
                      placeholder='End Date'
                      data-testid='end_date'
                    />
                    End Date
                  </Label>
                  {methods.formState.errors.end_date ? (
                    <p className='usa-error-message'>
                      {methods.formState.errors.end_date?.message}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Card.Body>
            <Card.Footer className='display-flex flex-justify-center'>
              {renderButtons()}
            </Card.Footer>
          </Card>
        </Card.Group>
      </form>
    </FormProvider>
  );
};
