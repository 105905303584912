import { BaseEntity } from '@/types';

import { Contract } from '../Contract';
import { Education } from '../Education';

export enum SearchUserEducation {
  Associate = 'Associate',
  Bachelor = 'Bachelors',
  Master = 'Masters',
  Doctorate = 'Doctoral',
}

export interface SearchProf extends BaseEntity {
  user: string;
  skill: {
    id: string;
    name: string;
  };
  level: {
    id: string;
    name: string;
    level: number;
  };
}

export interface User extends BaseEntity {
  avatar?: string;
  contract?: string | Contract;
  contract_team?: string;
  createdAt?: string;
  education?: string | Education;
  email: string;
  employee_id?: string;
  engagedly_id?: string;
  first_name: string;
  job_title?: string;
  joining_date?: string;
  last_name: string;
  professional_start_date?: string;
  trained_coding_challenges?: boolean;
  trained_technical_interviews?: boolean;
  state?: string;
  status?: string;
  updatedAt?: string;
  years_of_experience?: string;
}

export interface SearchResultUser extends User {
  education?: Education;
  required?: SearchProf[];
  optional?: SearchProf[];
  experience?: number;
}

export interface UserDetailed extends Omit<User, 'contract' | 'education'> {
  contract?: Contract;
  education?: Education;
}

export type CreateUserDTO = {
  firstName: string;
  lastName: string;
  email: string;
};

export type GetUserDTO = {
  body: UserDetailed;
  status: number;
  success: boolean;
};

export type GetUsersDTO = {
  body: User[];
  status: number;
  success: boolean;
};

export type SearchUsersDTO = {
  body: SearchResultUser[];
  status: number;
  success: boolean;
};
export interface ProfPayload {
  skill: string;
  level: number;
  required: boolean;
}
type MongoId = string;
export interface SearchUsersPayload {
  contract: MongoId[] | [];
  experience: number | null;
  education: SearchUserEducation[] | [];
  certifications: string[] | [];
  proficiencies: ProfPayload[] | [];
}
