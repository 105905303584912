import '@/App.scss';

import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { QueryClientProvider } from '@tanstack/react-query';
import { Button, Image } from '@team-lightfeather/ui-react-assets';
import { Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { queryClient } from '@/lib/react-query';
import { AuthProvider } from '@/providers/auth';
import { features } from '@/utils/featureToggles';

import lightfeather_logo from '../assets/img/LightFeather_Logo_2021.png';

const ErrorFallBack = ({ error }: FallbackProps) => {
  return (
    <>
      <div className='nav-bar'>
        <div className='nav-logo'>
          <Image alt='contract photo' src={lightfeather_logo} height='50' />
          <p>Skills Matrix</p>
        </div>
      </div>
      <div className='width-tablet text-left margin-x-auto'>
        <h1>Error!</h1>
        <p>{error.message}</p>
        <Button
          onClick={() => {
            window.history.back();
          }}
          className='width-10 margin-top-4'
        >
          Back
        </Button>
      </div>
    </>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <div className='App'>
      <Suspense fallback={<>Loading...</>}>
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
          <FeatureToggles features={features}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <Router>{children}</Router>
              </AuthProvider>
            </QueryClientProvider>
          </FeatureToggles>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};
