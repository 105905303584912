import { rest } from 'msw';

import { GetContractDTO } from '@/api/Contract';
import { API_URL } from '@/config';
import {
  mockContracts,
  mockContractsDeleteResponse,
  mockContractsGetResponse,
} from '@/mocks/resolvers/mockContracts';

export const contractHandlers = [
  rest.get(`${API_URL}/contracts`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContractsGetResponse));
  }),

  rest.get(`${API_URL}/contracts/1`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetContractDTO>({
        body: mockContracts[0],
        status: 200,
        success: true,
      })
    );
  }),

  rest.get(`${API_URL}/contracts/2`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetContractDTO>({
        body: mockContracts[1],
        status: 200,
        success: true,
      })
    );
  }),

  rest.get(`${API_URL}/contracts/undefined`, (req, res, ctx) => {
    return res(ctx.status(400));
  }),

  rest.post(`${API_URL}/contracts`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContracts[0]));
  }),

  rest.put(`${API_URL}/contracts/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContracts[0]));
  }),

  rest.delete(`${API_URL}/contracts/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockContractsDeleteResponse));
  }),
];
