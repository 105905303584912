import 'react-circular-progressbar/dist/styles.css';

import HorizontalProgressBar from '@ramonak/react-progress-bar';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

interface ProgressBarProps {
  classNames?: string;
  styles?: { [className: string]: string };
  isCircular?: boolean;
  minValue?: number;
  maxValue: number;
  value: number;
  valueText?: string;
  titleNode?: React.ReactNode;
  children?: React.ReactNode;
}

const defaultCircularStyles = {
  // Rotation of path and trail, in number of turns (0-1)
  //   rotation: 0.25,

  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
  //   strokeLinecap: 'butt',

  // Text size
  //   textSize: '16px',

  // How long animation takes to go from one percentage to another, in seconds
  //   pathTransitionDuration: 0.5,

  // Can specify path transition in more detail, or remove it entirely
  // pathTransition: 'none',

  // Colors
  pathColor: '#1b1b1b',
  textColor: '#1b1b1b',
  trailColor: '#d6d6d6',
  backgroundColor: '#3e98c7',
};

export const ProgressBar = ({
  classNames,
  styles,
  isCircular,
  minValue,
  maxValue,
  value,
  valueText,
  titleNode,
  children,
}: ProgressBarProps) => {
  const barElem = document.querySelector<HTMLDivElement>('[role="progressbar"]');
  barElem?.setAttribute('aria-label', 'progress-bar');

  return (
    <>
      <div className='grid-row'>
        {isCircular ? (
          <div className='grid-col circ-progress-bar'>
            <CircularProgressbarWithChildren
              className={classNames}
              styles={buildStyles(styles || defaultCircularStyles)}
              value={value}
              minValue={minValue || 0}
              maxValue={maxValue}
              text={valueText}
            >
              {children}
            </CircularProgressbarWithChildren>
            {titleNode}
          </div>
        ) : (
          <div className='grid-col horiz-progress-bar'>
            <HorizontalProgressBar
              height='0.5rem'
              bgColor='#1b1b1b'
              completed={value}
              maxCompleted={maxValue}
              className={classNames}
              barContainerClassName='container'
              labelClassName='label'
            />
            {titleNode}
          </div>
        )}
      </div>
    </>
  );
};
