import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/providers/auth';

export const AuthCallback = () => {
  const navigate = useNavigate();

  const { login } = useAuth();

  useEffect(() => {
    const transformedUrl = window.location.href.replace('#', '?');
    const url = new URL(transformedUrl);
    const accessToken = url.searchParams.get('access_token');

    if (accessToken) login(accessToken);

    navigate('/');
  }, [login, navigate]);

  return <></>;
};
