import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, ButtonGroup } from '@team-lightfeather/ui-react-assets';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useDeleteContractApi, useGetContract, useUpdateContract } from '@/api/Contract';
import { DeleteButton } from '@/components/DeleteButton/DeleteButton';

import { ContractInputs } from '../../ContractForm';
import { ContractFormWrapper } from '../../ContractFormWrapper';

interface EditContractFormProps {
  contractId: string | undefined;
}

const ContractSchema: yup.SchemaOf<ContractInputs> = yup.object().shape({
  name: yup.string().required('Contract name is required'),
  description: yup.string().optional(),
  point_of_contact: yup.string().required('Point of contact is required'),
  start_date: yup.string().required('Contract start date is required'),
  end_date: yup.string().required('Contract end date is required'),
  status: yup.string().required('Contract status is required'),
  prime_contractor: yup.string().required('Prime contractor is required'),
});

// eslint-disable-next-line react/prop-types
export const EditContractForm = ({ contractId }: EditContractFormProps) => {
  const contractQuery = useGetContract({ contractId: contractId ? contractId : '' });

  const contractUpdate = useUpdateContract();

  const contractDelete = useDeleteContractApi();

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      point_of_contact: '',
      start_date: '',
      end_date: '',
      status: '',
      prime_contractor: '',
    },
    resolver: yupResolver(ContractSchema),
  });

  useEffect(() => {
    if (!contractUpdate.isSuccess) return;
    toast.success('Contract updated successfully!');
  }, [contractUpdate.isSuccess]);

  useEffect(() => {
    const data = contractQuery?.data?.body;

    if (!data) return;
    methods.setValue('name', data.name ?? '');
    methods.setValue('description', data.description ?? '');
    methods.setValue('point_of_contact', data.point_of_contact ?? '');
    if (data.start_date) {
      const date = new Date(data.start_date);
      methods.setValue('start_date', date.toISOString().substring(0, 10));
    }
    if (data.end_date) {
      const date = new Date(data.end_date);
      methods.setValue('end_date', date.toISOString().substring(0, 10));
    }
    methods.setValue('status', data.status ?? '');
    methods.setValue('prime_contractor', data.prime_contractor ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractQuery.data]);

  if (contractQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (contractQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving contract!</Alert.Text>
      </Alert>
    );
  }

  const handleUpdateContractSubmit: SubmitHandler<ContractInputs> = (values: ContractInputs) => {
    if (contractId) {
      contractUpdate.mutate({
        id: contractId,
        data: values,
      });
    }
  };

  const renderButtons = () => (
    <ButtonGroup className='flex-justify-center'>
      <DeleteButton
        id={contractQuery?.data?.body?.id}
        buttonText={'Delete'}
        name={contractQuery?.data?.body?.name}
        category='category'
        navigateToUrl='/contracts'
        mutateFunction={contractDelete.mutate}
      />
      <Button name='Copy' />
      <Button name='Save' type='submit' />
      <Button name='Add LCATS' />
    </ButtonGroup>
  );

  return (
    <ContractFormWrapper
      heading='Edit Contract Details'
      methods={methods}
      onSubmit={handleUpdateContractSubmit}
      renderButtons={renderButtons}
    />
  );
};
