import { rest } from 'msw';

import { CreateProficiencyDTO, Proficiency } from '@/api/Proficiency';
import { API_URL } from '@/config';

import {
  mockProficiencies,
  mockProficienciesDeleteResponse,
  mockProficienciesGetResponse,
  searchUsersProficienciesResData,
} from '../resolvers/mockProficiencies';

const mockResponse = {
  isSuccess: true,
};

export const proficiencyHandlers = [
  rest.get(`${API_URL}/proficiencies`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProficienciesGetResponse));
  }),

  rest.get(`${API_URL}/proficiencies/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProficiencies[0]));
  }),

  rest.post<Proficiency, CreateProficiencyDTO>(`${API_URL}/proficiencies`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockResponse));
  }),

  rest.put(`${API_URL}/proficiencies/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProficiencies[0]));
  }),

  rest.post(`${API_URL}/proficiencies/find-user-proficiencies`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        status: 200,
        success: true,
        body: [searchUsersProficienciesResData],
      })
    );
  }),

  rest.delete(`${API_URL}/proficiencies/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProficienciesDeleteResponse));
  }),
];
