import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from '@/components/Layout';
import ToastContainer from '@/components/Toast/ToastContainer';
import { LogoutCallback } from '@/features/auth/LogoutCallback';
import { ContractEdit } from '@/features/contracts/ContractEdit/ContractEdit';
import { ContractsManagement } from '@/features/contracts/ContractsManagement';
import { ContractTeamDetails } from '@/features/contracts/ContractTeamDetails/ContractTeamDetails';
import { LevelsManagement } from '@/features/levels/LevelsManagement';
import { MatrixSearch } from '@/features/matrixSearch/MatrixSearch';
import { PageNotFound } from '@/features/pageNotFound/PageNotFound';
import { SearchUserProficiencies } from '@/features/searchUserProficiencies/SearchUserProficiencies';
import { SkillsManagement } from '@/features/skills/SkillsManagement';
import { CreateUser } from '@/features/users/CreateUser';
import { lazyImport } from '@/utils/lazyImport';

const { UsersList } = lazyImport(() => import('@/features/users/UsersList'), 'UsersList');
const { UserDetail } = lazyImport(() => import('@/features/users/UserDetail'), 'UserDetail');
const { EditUserProficiencyPage } = lazyImport(
  () => import('@/features/users/UserProficiencyEdit/EditUserProficiencyPage'),
  'EditUserProficiencyPage'
);
const { ContractDetail } = lazyImport(
  () => import('@/features/contracts/ContractDetail/ContractDetail'),
  'ContractDetail'
);

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<>Loading...</>}>
        <Outlet />
      </Suspense>
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </Layout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <MatrixSearch /> },
      { path: '/contracts', element: <ContractsManagement /> },
      { path: '/contracts/:contractId', element: <ContractDetail /> },
      { path: '/contracts/:contractId/edit', element: <ContractEdit /> },
      { path: '/contract-teams/:contractTeamId', element: <ContractTeamDetails /> },
      { path: '/users', element: <UsersList /> },
      { path: '/users/:userId', element: <UserDetail /> },
      { path: '/users/:userId/edit-proficiencies', element: <EditUserProficiencyPage /> },
      { path: '/users/create', element: <CreateUser /> },
      {
        path: '/proficiencies/search-proficiencies',
        element: <SearchUserProficiencies showShareSearchBtn={true} />,
      },
      { path: '/levels', element: <LevelsManagement /> },
      { path: '/skills', element: <SkillsManagement /> },
      { path: '/logout', element: <LogoutCallback /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
];
