import { rest } from 'msw';

import { CreateUserDTO, User } from '@/api/User';
import { API_URL } from '@/config';
import { mockUsers, mockUsersGetResponse, mockUsersSearch } from '@/mocks/resolvers/mockUsers';

const mockResponse = {
  isSuccess: true,
};

export const userHandlers = [
  rest.get(`${API_URL}/users`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockUsersGetResponse));
  }),

  rest.get(`${API_URL}/users/1`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockUsers[0]));
  }),

  rest.post(`${API_URL}/users/search`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockUsersSearch));
  }),

  rest.post<User, CreateUserDTO>(`${API_URL}/users/add`, (req, res, ctx) => {
    if (req.body.email === 'networkerror@uscis.dhs.gov') {
      return res(ctx.status(500));
    }
    return res(ctx.status(200), ctx.json(mockResponse));
  }),
];
