import { rest } from 'msw';

import { GetLevelDTO, GetLevelsDTO } from '@/api/Level';
import { API_URL } from '@/config';
import { mockLevels } from '@/mocks/resolvers/mockLevels';

export const levelHandlers = [
  rest.get(`${API_URL}/levels`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetLevelsDTO>({
        body: mockLevels,
        status: 200,
        success: true,
      })
    );
  }),

  rest.delete(`${API_URL}/levels/:id`, (req, res, ctx) => {
    const { id } = req.params;
    mockLevels.splice(
      mockLevels.findIndex((level) => level.id === id),
      1
    );
    return res(ctx.status(200), ctx.json({ id }));
  }),

  rest.put(`${API_URL}/levels/:id`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json<GetLevelDTO>({
        body: mockLevels[0],
        status: 200,
        success: true,
      })
    );
  }),
];
