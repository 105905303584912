import { Alert } from '@team-lightfeather/ui-react-assets';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CreateUserDTO, useCreateUserApi } from '@/api/User';

import { CreateUserForm } from './CreateUserForm';

export const CreateUser = () => {
  const { mutate, isError } = useCreateUserApi();

  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<CreateUserDTO> = (values) => {
    mutate(values, {
      onSuccess: () => {
        navigate('/users');
      },
    });
  };

  return (
    <>
      {isError && <Alert type='error'>There was a problem. Please try again</Alert>}

      <CreateUserForm onSubmit={handleSubmit} />
    </>
  );
};
