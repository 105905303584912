import { Contract, DeleteContractDTO, GetContractsDTO } from '@/api/Contract';

export const mockContracts: Contract[] = [
  {
    id: '1',
    name: 'Viper',
    description: 'Viper description',
    point_of_contact: 'John Tester',
    start_date: new Date('Aug 10, 2023').toISOString(),
    end_date: new Date('Aug 11, 2024').toISOString(),
    status: 'INACTIVE',
    prime_contractor: 'A2G',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    teams: ['1', '2'],
  },
  {
    id: '2',
    name: 'Speed',
    description: 'Speed description',
    point_of_contact: 'Mike Tester',
    start_date: new Date('Aug 10, 2020').toISOString(),
    end_date: new Date('Aug 11, 2024').toISOString(),
    status: 'ACTIVE',
    prime_contractor: 'Test Company',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    teams: [],
  },
];

export const mockContractsGetResponse: GetContractsDTO = {
  body: mockContracts,
  status: 200,
  success: true,
};

export const mockContractsDeleteResponse: DeleteContractDTO = {
  body: {
    message: `Proficiency with id ${mockContracts[0].id} deleted`,
  },
  status: 200,
  success: true,
};
