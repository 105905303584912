import { ContractTeam, GetContractTeamsDTO } from '@/api/ContractTeam';

export const mockContractTeams: ContractTeam[] = [
  {
    id: '1',
    name: 'VIPER - VDIS-Migrations',
    skills: ['1', '2'],
    contract: '1',
    updatedAt: '2023-08-08T17:40:20.451Z',
    createdAt: '2023-08-08T17:40:20.451Z',
  },
  {
    id: '2',
    name: 'VIPER - VDIS-Rebooters',
    skills: ['3', '4'],
    contract: '1',
    updatedAt: '2023-08-08T17:40:20.451Z',
    createdAt: '2023-08-08T17:40:20.451Z',
  },
];

export const mockContractTeamsGetResponse: GetContractTeamsDTO = {
  body: mockContractTeams,
  status: 200,
  success: true,
};
