import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Modal } from '@team-lightfeather/ui-react-assets';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  useDeleteContractTeamApi,
  useGetContractTeam,
  useUpdateContractTeam,
} from '@/api/ContractTeam';
import { useGetSkillsApi } from '@/api/Skill';
import { DeleteButton } from '@/components/DeleteButton/DeleteButton';

import {
  ContractTeamForm,
  ContractTeamInputs,
  skillOption,
} from '../ContractDetail/CreateContractTeam/ContractTeamForm';
import { ContractTeamDetailCard } from '../ContractTeamCards';
export const ContractTeamUpdateSchema: yup.SchemaOf<{
  name: string;
  skills: string[];
}> = yup.object().shape({
  name: yup.string().required('Contract Team name is required'),
  skills: yup
    .array()
    .min(1, 'Please select at least 1 skill')
    .test('empty-check', 'Please select at least 1 skill', (skill) => skill?.[0] !== '')
    .required(),
});

export const ContractTeamDetails = () => {
  const methods = useForm({
    defaultValues: {
      name: '',
      skills: [''],
    },
    resolver: yupResolver(ContractTeamUpdateSchema),
  });

  const { contractTeamId } = useParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const contractTeamQuery = useGetContractTeam({
    contractTeamId: contractTeamId ? contractTeamId : '',
  });
  const contractTeamUpdate = useUpdateContractTeam();
  const { mutate } = useDeleteContractTeamApi();
  const skillsQuery = useGetSkillsApi();

  const handleUpdateContractTeam: SubmitHandler<ContractTeamInputs> = (
    values: ContractTeamInputs
  ) => {
    if (contractTeamId) {
      contractTeamUpdate.mutate(
        {
          id: contractTeamId,
          data: { ...values, contract: contractTeamQuery.data?.body.contract || '' },
        },
        {
          onSuccess: () => {
            setIsEditModalOpen(false);
          },
        }
      );
    }
  };

  // Set forms values FROM previously saved data values
  useEffect(() => {
    const data = contractTeamQuery?.data?.body;

    if (!data) return;
    methods.setValue('name', data.name ?? '');
    methods.setValue('skills', data.skills ?? []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractTeamQuery.data]);

  if (contractTeamQuery.isLoading || skillsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (contractTeamQuery.isError || skillsQuery.isError) {
    return (
      <Alert type='error'>
        <Alert.Text>Error retrieving contract team!</Alert.Text>
      </Alert>
    );
  }

  const defaultSkills: skillOption[] = [];
  contractTeamQuery.data.body.skills?.map((skillId) => {
    const skillIndex = skillsQuery.data.findIndex((skill) => skill.id == skillId);
    defaultSkills.push({
      value: skillId,
      label: skillsQuery.data[skillIndex].name,
    });
  });

  return (
    <>
      <ContractTeamDetailCard />
      <Modal
        variant='large'
        aria-labelledby='UpdateLevelModal'
        open={isEditModalOpen}
        className='modal-max-width-content'
        close={() => setIsEditModalOpen(false)}
      >
        <ContractTeamForm
          heading='Edit Contract Team'
          methods={methods}
          onSubmit={handleUpdateContractTeam}
          defaultValue={defaultSkills}
        />
      </Modal>
      <Button
        name='Edit Team'
        onClick={() => {
          setIsEditModalOpen(true);
        }}
      />
      <DeleteButton
        id={contractTeamQuery.data.body.id}
        buttonText={'Delete Team'}
        name={contractTeamQuery.data.body.name}
        category={'contract team'}
        navigateToUrl={`/contracts/${contractTeamQuery.data.body.contract}`}
        mutateFunction={mutate}
      />
    </>
  );
};
