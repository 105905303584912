import { authHandlers } from './authHandlers';
import { contractHandlers } from './contractHandlers';
import { contractTeamHandlers } from './contractTeamHandlers';
import { educationHandlers } from './educationHandlers';
import { levelHandlers } from './levelHandler';
import { proficiencyHandlers } from './proficiencyHandlers';
import { skillHandlers } from './skillHandlers';
import { userHandlers } from './userHandlers';

const handlers = [
  ...authHandlers,
  ...userHandlers,
  ...skillHandlers,
  ...levelHandlers,
  ...proficiencyHandlers,
  ...contractHandlers,
  ...contractTeamHandlers,
  ...educationHandlers,
];

export default handlers;
