import { ReactNode } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';

import { ContractForm, ContractInputs } from './ContractForm';

interface ContractFormWrapperProps {
  heading: string;
  onSubmit: SubmitHandler<ContractInputs>;
  methods: UseFormReturn<
    {
      name: string;
      description: string;
      point_of_contact: string;
      start_date: string;
      end_date: string;
      status: string;
      prime_contractor: string;
    },
    ContractInputs
  >;
  renderButtons: () => ReactNode;
}

// eslint-disable-next-line react/prop-types
export const ContractFormWrapper = ({
  heading,
  methods,
  onSubmit,
  renderButtons,
}: ContractFormWrapperProps) => {
  return (
    <div>
      <ContractForm
        heading={heading}
        methods={methods}
        onSubmit={onSubmit}
        renderButtons={renderButtons}
      />
    </div>
  );
};
